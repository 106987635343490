import api from "@/base/utils/request";
import download from "@/base/utils/download";
import fetchFile from "@/base/utils/download";
import upload from "@/base/utils/upload4";

// 获取企业列表
export const CompanyList = data => {
  return api({
    url: "/admin/cyc/CompanyData/index",
    method: "post",
    data
  });
};

// 删除移入回收站
export const softDelete = data => {
  return api({
    url: "/admin/cyc/CompanyData/softDelete",
    method: "post",
    data
  });
};

// 回收站永久删除
export const Delete = data => {
  return api({
    url: "/admin/cyc/CompanyData/del",
    method: "post",
    data
  });
};

// 回收站还原数据
export const putBack = data => {
  return api({
    url: "/admin/cyc/CompanyData/putBack",
    method: "post",
    data
  });
};


//获取举报记录
export const reportLog = data => {
  return api({
    url: "/admin/cyc/CompanyData/reportLog",
    method: "post",
    data
  });
};

//列表导出
export const companyExport = data => {
  return download({
    url: "/admin/cyc/company_data/export",
    method: "post",
    data
  });
};

// 设置置顶
export const setTop = data => {
  return api({
    url: '/admin/cyc/companyData/top',
    method: 'post',
    data
  })
}

// 设置置顶
export const cancelTop = data => {
  return api({
    url: '/admin/cyc/companyData/cancelTop',
    method: 'post',
    data
  })
}

export const getImportCompanyTemplate = () => {
  return fetchFile({
    url: '/admin/cyc/CompanyData/importCompaniesXlsx',
    method: 'post',
    download: true,
    data: {}
  });
}

export const importCompanies = (data) => {
  return api({
    url: '/admin/cyc/CompanyData/importCompanies',
    method: 'post',
    data
  });
}

export const updateLocation = (data) => {
  return api({
    url: '/admin/cyc/CompanyData/updateLocation',
    method: 'post',
    data,
    notCancel: true,
    errorToast: false
  })
}
