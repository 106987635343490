<template>
  <LayoutFilter :id="randomId" :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true" :onExport="onExport"
    ref="layoutFilter">
    <el-form :model="form" ref="logFilterForm" label-width="85px" size="medium" class="filter-from">
      <!-- Line One Not Member -->
      <div class="filter-item" v-if="(form.tab === 'register' || form.tab === 'nopass') && pageset === 'user'">
        <el-form-item v-if="form.tab === 'register'" label="用户名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="form.tab === 'nopass'" label="用户名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="申请职务：" prop="apply_position_ids">
          <el-cascader v-model="form.apply_position_ids" :options="parentPositionList" :props="props"
            placeholder="请选择">
          </el-cascader>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" />
        </el-form-item>
      </div>
      <!-- Line Two Not Member -->
      <div v-if="(form.tab === 'register' || form.tab === 'nopass') && pageset === 'user'" class="filter-item">
        <el-form-item v-if="form.tab === 'register'" label="年龄：" prop="age">
          <el-date-picker type="year" v-model="age" placeholder="请选择" clearable style="width: 100%;" />
        </el-form-item>
        <el-form-item label="性别：" prop="gender">
          <el-select v-model="gender" placeholder="请选择">
            <el-option v-for="item in genderList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.tab === 'nopass'" label="籍贯：" prop="native_place">
          <el-cascader :options="placeList" v-model="form.native_place" :props="optionProp"
                       @change="handleChange" />
        </el-form-item>
      </div>
      <!-- Line Three Not Number -->
      <div v-if="form.tab === 'register' && pageset === 'user'" class="filter-item">
        <el-form-item label="民族：" prop="national">
          <el-select v-model="national" placeholder="请选择" clearable>
            <el-option v-for="item in national_arr" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="籍贯：" prop="native_place">
          <!-- <el-select v-model="form.native_place" placeholder="请选择">
            <el-option v-for="(item, i) in placeList" :key="i" :label="item" :value="item">
            </el-option>
          </el-select> -->
          <el-cascader :options="placeList" v-model="form.native_place" :props="optionProp"
                       @change="handleChange" />
        </el-form-item>
        <el-form-item  label="政治面貌：" prop="political_status">
          <el-select v-model="political_status" placeholder="请选择" clearable>
            <el-option v-for="item in political_arr" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
      </div>
      <div v-if="pageset === 'unit'" class="filter-item">
        <el-form-item label="单位名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="申请职务：" prop="apply_position_ids">
          <el-cascader v-model="form.apply_position_ids" :options="parentPositionList" :props="props"
                       placeholder="请选择" clearable>
          </el-cascader>
        </el-form-item>
      </div>
      <div v-if="pageset === 'unit'" class="filter-item">
        <el-form-item label="授权代表人：" prop="unit_username">
          <el-input v-model="form.unit_username" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入" clearable />
        </el-form-item>
      </div>
      <!-- Line Four -->
      <div class="filter-item">
        <DatePeriodPicker v-if="form.tab === 'register' || form.tab === 'nopass'" label="提交时间：" start-prop="start_time"
          :start-time.sync="form.start_time" end-prop="end_time" :end-time.sync="form.end_time" />
        <DatePeriodPicker v-else label="入会时间：" start-prop="start_join_time"
          :start-time.sync="form.start_join_time" end-prop="end_join_time"
          :end-time.sync="form.end_join_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import {
  positionLevel,
  documentMemberList, nationality, political, exportRejectList
} from "../../api/member/member-list";
import {randomString, toElementTimestamp, toShortTimestamp} from "@/base/utils/tool";

const nativePlace = require("../../assets/jsons/address.json");
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    pageset: {
      type: String,
      default: 'user'
    },
    uploadFilter: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      randomId: randomString(6),
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      parentPositionList: [],
      genderList: [
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      optionProp: {
        expandTrigger: "hover",
        label: "label",
        value: "label",
      },
      placeList: nativePlace,
      dataTypeList: [
        { id: 0, name: "真实用户" },
        { id: 1, name: "后台添加" },
      ],
      credentialsList: [
        { id: 0, name: "有" },
        { id: 1, name: "无" },
      ],
      payStatusList: [
        { id: 0, name: "未缴费" },
        { id: 1, name: "已缴费" },
        { id: 2, name: "无需缴费" },
        { id: 3, name: "需补差价" },
      ],
      national_arr: [],
      political_arr: [],
    };
  },
  methods: {
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data;
        })
        .catch(() => {});
    },
    // 点击选择籍贯
    handleChange(e) {
      this.form.native_place = e.join(",");
      // console.log("111", e.join(","));
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      if (this.form.tab === 'nopass') {
        this.form.native_place = '';
        this.form.position = '';
      }
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      if (this.form.tab === 'nopass') {
        this.parentPositionList.forEach(item => {
          if (item.id === this.form.apply_position_ids[0]) {
            item.position.forEach(item => {
              if (item.id === this.form.apply_position_ids[1]) {
                return this.form.position = item.name
              }
            })
          }
        })
        return this.uploadFilter(this.form);
      }
      this.uploadFilter(this.form);
    },
    // 导出会员列表
    onExport() {
      const postData = { ...this.form, ids: this.ids, page: this.page, pageset: this.pageset };
      if (this.filterForm.tab === 'nopass') {
        return exportRejectList(postData).catch(() => {});
      }
      return documentMemberList(postData).catch(() => {});
    },
  },
  created() {
    this.getPositionLevel();
    nationality().then(res => {
      this.national_arr = res.data
    }).catch(err => {
    })
    political().then(res => {
      this.political_arr = res.data
    }).catch(err => {
    })
    this.$nextTick(() => {
      this.$layoutFilter = this.$refs.layoutFilter;
    });
  },
  watch: {
    positionIds(val) {
      this.position_id = val[val.length - 1] ? val[val.length - 1] : -1;
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val;
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? "" : this.form.organization_id;
      },
      set(val) {
        this.form.organization_id = val;
      },
    },
    positionIds: {
      get() {
        return this.form.positionIds;
      },
      set(val) {
        this.form.positionIds = val;
      },
    },
    age: {
      get() {
        return this.form.age === -1 ? '' : this.form.age.toString()
      },
      set(val) {
        /* 点击清空图标，val 为 null */
        this.form.age = typeof val === 'string' && val ? Number(val) : (val === null ? -1 : val)
      }
    },
    gender: {
      get() {
        return this.form.gender == -1 ? "" : this.form.gender;
      },
      set(val) {
        this.form.gender = val;
      },
    },
    national: {
      get() {
        return this.form.national
      },
      set(val) {
        return this.form.national = val
      }
    },
    political_status: {
      get() {
        return this.form.political_status
      },
      set(val) {
        return this.form.political_status = val
      }
    },
    data_type: {
      get() {
        return this.form.data_type == -1 ? "" : this.form.data_type;
      },
      set(val) {
        this.form.data_type = val;
      },
    },
    credentials: {
      get() {
        return this.form.credentials == -1 ? "" : this.form.credentials;
      },
      set(val) {
        this.form.credentials = val;
      },
    },
    pay_status: {
      get() {
        return this.form.pay_status == -1 ? "" : this.form.pay_status;
      },
      set(val) {
        this.form.pay_status = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
