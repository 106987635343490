<template>
  <el-dialog :title="'选择非会员'" :visible.sync="openDialog" width="88%"
    :show-close="false" class="dialog-vertical" @close="cancel">
    <div class="head-filter" :style="'width: 50%'">
      <el-input v-model="filterForm.nickname" placeholder="请输入用户名称" @change="handleFilter" suffix-icon="el-icon-search"/>
      <el-input v-model="filterForm.phone" placeholder="请输入手机号码" @change="handleFilter" suffix-icon="el-icon-search">
<!--        <i class="el-icon-search el-input__icon" slot="suffix" />-->
      </el-input>
    </div>
    <div class="member-list" style="margin-top: 35px">
      <el-table :data="memberList" style="width: 100%" :height="450" ref="multipleTable"
        :highlight-current-row="!selectType" @row-click="handleCurrentChange" @selection-change="handleSelectionChange"
        v-loading="loading" v-loadmore="LoadMore">
        <!-- 多选 -->
        <el-table-column ref="selection" fixed type="selection" width="70" v-if="selectType"
          :key="Math.random()"/>
        <!-- 单选 -->
        <el-table-column fixed width="70" v-if="!selectType" :key="Math.random()">
          <template slot-scope="scope">
            <el-radio :label="scope.row" v-model="currentRow"
              :disabled="(selectedMembers.findIndex((item) => item.user_id === scope.row.user_id) !== -1)">
              {{ "&nbsp" }}</el-radio>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''" :key="index" :min-width="th.minWidth || ''">
            <template slot-scope="{row}">
              <p v-if="th.prop !== 'avatar'" style="white-space: pre-wrap;">{{ row[th.prop] | placeholder }}</p>
              <list-image v-else :src="row[th.prop] || ''" border-radius="50%" fit="cover" />
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import { getList } from '../../../non-cyc-user/api/list'
import ListImage from '../../../../base/components/List/ListImage'

export default {
  name: 'not-member-selector',
  components: { ListImage },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 1,
    },
    // 选择数量限制
    limit: {
      type: Number,
      default: 99,
    },
  },
  data() {
    const thead = [
      { label: "ID", prop: "id" },
      { label: "头像", prop: "avatar" },
      { label: "用户名称", prop: "nickname" },
      { label: "手机号码", prop: "phone", minWidth: 100 },
      { label: "最后登录时间", prop: "last_login_time", minWidth: 150 },
    ];
    return {
      loading: false, //加载
      thead,
      memberList: [],
      //筛选对象
      filterForm: {
        nickname: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
        phone: ''
      },
      getable: true, //是否可以请求列表
      currentRow: "", // 单选时选中的数据项
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取会员列表
    getSearchMemberList: debounce(function (pageData) {
      if (this.getable) {
        this.loading = true;
        getList(pageData)
          .then((res) => {
            const { data } = res;
            if (pageData.page === 1) {
              this.memberList = data.data;
            } else {
              this.memberList = [...this.memberList, ...data.data];
            }
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false;
            }
            this.filterForm.page++;
            this.loading = false;
          })
          .catch(() => { });
      }
    }, 200),
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getSearchMemberList(fliterData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        if (selection.length > this.limit) {
          this.selectArr = selection.slice(0, this.limit);
          const leftArr = selection.slice(this.limit);
          this.$message.error(`最多可选择 ${this.limit} 个非会员`);
          leftArr.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el, false);
          });
        } else {
          this.selectArr = [...selection];
        }
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      if (!this.selectType) {
        this.currentRow = val;
      } else {
        if (this.selectedMembers.findIndex((item) => item.user_id === val.user_id) !== -1) {
          return;
        } else {
          this.memberList.forEach((element) => {
            if (val.user_id === element.user_id) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(element);
              });
            }
          });
        }
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, "Load");
      }
    },
    // 确定
    submit() {
      if (this.selectType) {
        if (!this.selectArr.length) {
          this.$message.info("请选择数据项");
        } else {
          this.selectedMembers = this.selectedType === "total" ?
            [...this.selectedMembers, ...this.selectArr] :
            [...this.selectArr];
          this.$refs.multipleTable.clearSelection();
          this.selectArr = [];
          this.openDialog = false;
        }
      } else {
        if (!this.currentRow) {
          this.$message.info("请选择数据项");
        } else {
          this.selectedMembers.splice(this.currentIndex, 1, this.currentRow);
          this.$emit("changeSelect", this.selectedMembers);
          this.openDialog = false;
        }
      }
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        const data = { ...this.filterForm, page: 1 };
        this.getSearchMemberList(data);
      }
    },
    memberList: {
      deep: true,
      handler(val) {
        console.log(this.memberList, "this.memberList");
        this.memberList.forEach((el) => {
          if (this.selectArr.findIndex((elem) => elem.user_id === el.user_id) !== -1 && this.$refs.multipleTable) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true);
            });
          }
        });
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedMembers: {
      get() {
        return [];
      },
      set(val) {
        this.$emit("changeSelect", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  // width: 80%;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  justify-content: flex-end;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
