<template>
  <div class="list-page">
    <div
      class="third-nav"
      v-if="
        tenantVersion !== 'school' &&
        thirdNavConfig.personal_user_enable &&
        thirdNavConfig.unit_user_enable
      "
    >
      <el-tabs v-model="activeName" @tab-click="changeQuery">
        <el-tab-pane label="个人入会" name="user">
          <template #label
          >个人入会
            <!-- 情况一：每一个tab视为不同节点 -->
            <el-badge
                :hidden="!(messagesData['userCheckingCount'] > 0)"
                :value="messagesData['userCheckingCount'] || ''"
                style="overflow: visible"
                v-if="messagesData"
            ></el-badge>
          </template>
        </el-tab-pane>
        <el-tab-pane label="单位入会" name="unit">
          <template #label
          >单位入会
            <!-- 情况一：每一个tab视为不同节点 -->
            <el-badge
                :hidden="!(messagesData['unitUserCheckingCount'] > 0)"
                :value="messagesData['unitUserCheckingCount'] || ''"
                style="overflow: visible"
                v-if="messagesData"
            ></el-badge>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 校友版 -->
    <list-layout
      v-if="tenantVersion === 'school'"
      ref="schoolMateList"
      :filter-form="filterForm"
      :thead="schoolHead"
      :tab-arr="schoolArr"
      :on-fetch="getMemberList"
      :striped="false"
      :span-method="arraySpanMethod"
      :on-span="handleMemberList"
      @command="handleClickRow"
      switchRouteWhenTabChange="CycUserList"
      :storage-filter-form="true"
    >
      <div slot="top" class="top-btn" v-if="filterForm.tab === 'normal'">
        <el-button type="primary" size="small" @click="isShowAdd = true"
          >添加校友</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="isShowImportMembers = true"
          >批量导入校友</el-button
        >
      </div>
      <template #filter="{ pageData, idList }">
        <list-filter-school
          :filter-form="filterForm"
          :ids="idList"
          :upload-filter="ok"
        />
      </template>
      <template v-if="filterForm.tab === 'normal'" #tabletop="{ idList, list }">
        <div class="list-btn">
          <el-button
            type="danger"
            size="small"
            @click="handleQuit(idList, list)"
            >批量退会</el-button
          >
        </div>
      </template>
      <template v-if="filterForm.tab !== 'checking'" #item_data_type="{ row }">
        <!-- 数据类型 -->
        <span>{{ row.data_type ? '后台导入' : '真实用户' }}</span>
      </template>
      <template
        v-if="filterForm.tab !== 'checking'"
        #item_status_text="{ row }"
      >
        <list-status-text
          :text="row.status_text"
          :type="row.status_text === '已认证' ? 'success' : 'danger'"
        />
      </template>
      <template #item_student_infos="{ row }">
        <p style="white-space: pre-wrap">{{ row.student_infos }}</p>
      </template>
    </list-layout>
    <!-- 会员入会 -->
    <!--
:on-span="handleMemberList"
    -->
    <list-layout
      v-else-if="thirdNavConfig.personal_user_enable && activeName === 'user'"
      ref="memberList"
      :filter-form="filterForm"
      :thead="thead"
      :span-method="arraySpanMethod"
      :on-fetch="getMemberList"
      @command="handleClickRow"
      switchRouteWhenTabChange="CycUserList"
      :tab-arr="tabArr"
      :storage-filter-form="true"
      row-key="user_position_id"
      key="user"
    >
      <div
        slot="top"
        class="top-btn"
        v-if="filterForm.tab === 'formal' || filterForm.tab === 'pre'"
      >
        <el-button type="primary" size="small" @click="isShowAdd = true"
          >添加会员</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="isShowImportMembers = true"
          >批量导入会员</el-button
        >
      </div>
      <template #filter="{ pageData, idList }">
        <list-filter
          v-if="filterForm.tab === 'formal' || filterForm.tab === 'pre'"
          :filterForm="filterForm"
          :ids="idList"
          :page="pageData.page"
          :uploadFilter="ok"
          ref="listFilter"
          @ready="initLayout()"
        />
        <list-filter-register
          v-else
          key="user"
          :filterForm="filterForm"
          :ids="idList"
          :page="pageData.page"
          :uploadFilter="ok"
          ref="listFilter"
        />
      </template>
      <!--      <template #tabs="{ loading }">-->
      <!--        <list-tabs-->
      <!--          v-model="filterForm.tab"-->
      <!--          :isLoading="loading"-->
      <!--          :tabs="tabArr"-->
      <!--          @tab-click="handleClick"-->
      <!--        />-->
      <!--      </template>-->
      <template v-if="filterForm.tab === 'register'" #tabletop="{ idList }">
        <div class="list-btn">
          <!-- <el-button type="success" size="small" @click="handlePass(1, filterForm.tab)">通过</el-button> -->
          <el-button
            type="warning"
            size="small"
            @click="handlePass(0, filterForm.tab, idList)"
            >不通过</el-button
          >
        </div>
      </template>
      <template
        v-if="filterForm.tab === 'formal' || filterForm.tab === 'pre'"
        #tabletop="{ idList, list }"
      >
        <div class="list-btn">
          <!-- 个人会员批量退会 -->
          <el-button
            type="danger"
            size="small"
            @click="handleQuit(idList, list)"
            >批量退会</el-button
          >
          <!-- 批量修改会费状态 -->
          <el-button
            type="primary"
            size="small"
            @click="handleFee(idList, list)"
            >批量修改会费状态</el-button
          >
        </div>
      </template>
      <template v-if="filterForm.tab !== 'register'" #item_data_type="{ row }">
        <!-- 数据类型 -->
        <span>{{ row.data_type ? '后台导入' : '真实用户' }}</span>
      </template>
    </list-layout>
    <!-- 单位入会 -->
    <list-layout
      v-else-if="thirdNavConfig.unit_user_enable && activeName === 'unit'"
      ref="unitList"
      :filter-form="filterForm"
      :thead="unitHead"
      :tab-arr="unitTabArr"
      :span-method="arraySpanMethod"
      :on-fetch="getMemberList"
      @command="handleClickRow"
      switchRouteWhenTabChange="CycUserList"
      :storage-filter-form="true"
      key="unit"
      row-key="user_position_id"
    >
      <div
        slot="top"
        class="top-btn"
        v-if="filterForm.tab === 'formal' || filterForm.tab === 'pre'"
      >
        <el-button type="primary" size="small" @click="isShowAdd = true"
          >添加单位</el-button
        >
        <el-button type="primary" size="small" @click="isShowImportUnits = true"
          >批量导入单位</el-button
        >
      </div>
      <template #filter="{ pageData, idList }">
        <unit-filter
          v-if="filterForm.tab === 'formal' || filterForm.tab === 'pre'"
          :filterForm="filterForm"
          :ids="idList"
          :page="pageData.page"
          :uploadFilter="ok"
        />
        <list-filter-register
          v-else
          pageset="unit"
          key="unit"
          :filterForm="filterForm"
          :ids="idList"
          :page="pageData.page"
          :uploadFilter="ok"
        />
      </template>
      <template v-if="filterForm.tab === 'register'" #tabletop="{ idList }">
        <div class="list-btn">
          <!-- <el-button type="success" size="small" @click="handlePass(1, filterForm.tab)">通过</el-button> -->
          <el-button
            type="warning"
            size="small"
            @click="handlePass(0, filterForm.tab, idList)"
            >不通过</el-button
          >
        </div>
      </template>
      <template
        v-if="filterForm.tab === 'formal' || filterForm.tab === 'pre'"
        #tabletop="{ idList, list }"
      >
        <div class="list-btn">
          <el-button
            type="danger"
            size="small"
            @click="handleQuit(idList, list)"
            >批量退会</el-button
          >
          <el-button
            type="info"
            size="small"
            :loading="transformLoading"
            @click="clickTransformMany(idList)"
            >转为个人会员</el-button
          >
          <!-- 批量修改会费状态 -->
          <el-button type="danger" size="small" @click="handleFee(idList, list)"
            >批量修改会费状态</el-button
          >
        </div>
      </template>
      <template v-if="filterForm.tab !== 'register'" #item_data_type="{ row }">
        <!-- 数据类型 -->
        <span>{{ row.data_type ? '后台导入' : '真实用户' }}</span>
      </template>
      <!--      <template #item_avatar="{row}">-->
      <!--        <list-image v-if="row.avatar" :src="row.avatar" />-->
      <!--      </template>-->
    </list-layout>

    <ReasonDialog
      v-model="openReason"
      :pageset="activeName"
      :IDs="Ids"
      :PositionList="PositionList"
      :CheckType="current_check_type"
      :SelectShow="isShowSelect"
      :updateList="refreshList"
    />
    <Exit
      v-model="openExit"
      :PositionList="PositionList"
      :updateList="refreshList"
    />
    <import-members v-model="isShowImportMembers" :updateList="refreshList" />
    <import-units v-model="isShowImportUnits" :updateList="refreshList" />
    <!-- 添加校友弹窗 -->
    <addStudent
      v-if="tenantVersion === 'school'"
      v-model="isShowAdd"
      un-verified-route-name="AddUncertified"
      :filter-form="filterForm"
      :updateList="refreshList"
    />
    <add-unit-dialog
      v-else-if="thirdNavConfig.unit_user_enable && activeName === 'unit'"
      v-model="isShowAdd"
      :filter-form="filterForm"
      :updateList="refreshList"
      @enterprise-dialog="isShowEnterprises = true"
    />
    <add-member-dialog
      v-else
      v-model="isShowAdd"
      :filter-form="filterForm"
      :updateList="refreshList"
      @non-user-dialog="isShowNotMember = true"
    />
    <not-member-selector
      v-model="isShowNotMember"
      :select-type="0"
      @changeSelect="AddMember"
    />
    <enterprise-selector
      v-model="isShowEnterprises"
      :select-type="0"
      @changeSelect="addUnit"
    />
    <!-- 不通过原因弹窗 -->
    <NopassReason v-model="isShowReason" :form="noPassForm" />
    <!-- 修改入会时间弹窗 -->
    <ChangeJoinTime
      :openDialog="openDialog"
      :user_position_id="user_position_id"
      @hideDialog="hideJoinTimeDialog"
    />
    <ExperienceDialog
      :openDialog="experienceDialog.show"
      :user_id="experienceDialog.user_id"
      @hideDialog="
        () => {
          experienceDialog.show = false
        }
      "
    ></ExperienceDialog>
    <!-- 赠送积分 -->
    <add-integral
      :user="user"
      :open="isShowAddIntegral"
      @closeDialog="isShowAddIntegral = false"
    />
    <ChangePayStatus
      v-model="isOpenChangePayStatus"
      :PayForm="StatusPayForm"
      :form.sync="StatusPayForm"
      target="user"
      :on-submit="tryChangePayStatus"
      :is-complete="isComplete"
    >
      <p
        style="white-space: pre-wrap; margin-top: 20px"
        v-html="changeLogs"
      ></p>
    </ChangePayStatus>
  </div>
</template>

<script>
import listFilter from '../../components/Member/ListFilter'
import ReasonDialog from '../../components/Member/ReasonDialog'
import importMembers from '../../components/Member/ImportMembers'
import importUnits from '../../components/Member/ImportUnits'
import Exit from '../../components/Member/Exit'
import {
  changeApplyType,
  changeFeeStatus,
  ExitOrganization,
  memberList,
  rejectList,
} from '../../api/member/member-list'
import { Checked } from '@/modules/organization-admin/api/member/member-check'
import ListLayout from '../../../../base/layout/ListLayout'
import ListFilterRegister from '../../components/Member/ListFilterRegister'
import ListFilterSchool from '../../components/Member/ListFilterSchool'
import { ClassMembers } from '@/modules/organization-admin/api/school-student-list'
import ListStatusText from '@/base/components/List/ListStatusText'
import AddStudent from '@/modules/organization-admin/components/class-members/addStudent'
import NopassReason from '../../components/Member/NopassReason'
import { getRoleSetting } from '@/modules/organization-admin/api/other-config'
import RouterTabs from '@/base/components/Default/RouterTabs'
import UnitFilter from '@/modules/organization-admin/components/Member/UnitFilter'
import ListImage from '@/base/components/List/ListImage'
import ChangeJoinTime from '../../components/Member/ChangeJoinTime'
import ExperienceDialog from '../../components/Member/ExperienceDialog'
import AddMemberDialog from '../../components/Member/AddMemberDialog'
import NotMemberSelector from '../../components/Member/NotMemberSelector'
import AddUnitDialog from '../../components/Member/AddUnitDialog'
import EnterpriseSelector from '../../components/Member/EnterpriseSelector'
import AddIntegral from '../../components/Member/AddIntegral'
import ChangePayStatus from '../../components/Member/ChangePayStatus'

import { mapState } from 'vuex'

import AQM from '@/base/utils/async-queue-manager' //异步队列管理
var manager = null

export default {
  data() {
    // 展开更多配置
    const moreHandleList = [
      { name: '我的企业', command: 'MyCompany' },
      { name: '我的资源', command: 'MyResources' },
      { name: '我的活动', command: 'MyActivity' },
      { name: '我的留言', command: 'MyLeaveMsg' },
      { name: '我的订单', command: 'MyOrder' },
      { name: '职务记录', command: 'PositionRecode' },
      { name: '被举报记录', command: 'ReportRecode' },
      // { name: "查看转账凭证 ", command: "myCredential" },
      { name: '转账记录', command: 'myCredential' },
      { name: '邀请收益', command: 'InvitationRevenue' },
      { name: '等级积分记录', command: 'myIncomeRecords' },
      { name: '赠送积分', command: 'AddIntegral' },
      { name: '分配成长值', command: 'Experience' },
      { name: '修改入会时间', command: 'changeJoinTime' },
      {
        name: '退会',
        command: 'Exit',
        visible: (row) => {
          return (
            row.userPosition.length > 0 && row.userPosition[0].position_name
          )
        },
      },
    ]
    return {
      // 赠送积分
      user: null,
      isShowAddIntegral: false,

      isShowNotMember: false,
      isShowEnterprises: false,

      openDialog: false,
      user_position_id: '',

      experienceDialog: {
        user_id: '',
        show: false,
      },
      activeName: 'user',
      thirdNavConfig: {
        personal_user_enable: 0,
        unit_user_enable: 0,
      },
      tabArr: [
        { label: '正式会员', name: 'formal' },
        { label: '预备会员', name: 'pre' },
        { label: '申请入会用户', name: 'register', badge: 1, url: 'userCheckingCount' },
        { label: '不通过记录', name: 'nopass' },
      ],
      unitTabArr: [
        { label: '正式会员', name: 'formal' },
        { label: '预备会员', name: 'pre' },
        { label: '申请入会用户', name: 'register', badge: 1, url: 'unitUserCheckingCount' },
        { label: '不通过记录', name: 'nopass' },
      ],
      schoolArr: [
        { label: '校友列表', name: 'normal' },
        { label: '审核列表', name: 'register' },
        { label: '不通过记录', name: 'nopass' },
      ],
      thead: {
        formal: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '会员编号', prop: 'member_number', minWidth: 100 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '会员名称', prop: 'nickname', minWidth: 110 },
          { label: '年龄', prop: 'age_text' },
          { label: '性别', prop: 'gender_text' },
          { label: '民族', prop: 'national', minWidth: 100 },
          { label: '籍贯', prop: 'native_place', minWidth: 100 },
          { label: '手机号码', prop: 'phones', minWidth: 120 },
          { label: '政治面貌', prop: 'political_status', minWidth: 120 },
          { label: '会内职务', prop: 'title_name', minWidth: 150 },
          { label: '会费状态', prop: 'pay_status_text', minWidth: 90 },
          { label: '推荐人', prop: 'referee', minWidth: 110 },
          {
            label: '入会时间',
            prop: 'join_time',
            minWidth: 160,
            // sortable: 'custom' // If you need remote sorting from backend, set sortable to custom
          },
          { label: '数据类型', prop: 'data_type', type: 'slot', minWidth: 100 },
          { label: '邀请人数', prop: 'invite_count', minWidth: 100 },
          {
            label: '最后登录时间',
            prop: 'last_login_time',
            minWidth: 160,
            // sortable: 'custom'
          },
          {
            type: 'operation',
            label: '操作',
            width: 120,
            operations: [
              { command: 'profile', text: '查看' },
              { command: 'more', text: '更多', operations: moreHandleList },
            ],
          },
        ],
        pre: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '会员编号', prop: 'member_number', minWidth: 100 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '会员名称', prop: 'nickname', minWidth: 110 },
          { label: '性别', prop: 'gender_text' },
          { label: '籍贯', prop: 'native_place', minWidth: 100 },
          { label: '手机号码', prop: 'phones', minWidth: 120 },
          { label: '会内职务', prop: 'title_name', minWidth: 150 },
          { label: '会费状态', prop: 'pay_status_text', minWidth: 90 },
          { label: '推荐人', prop: 'referee', minWidth: 110 },
          { label: '申请架构', prop: 'join_organization_name', minWidth: 110 },
          { label: '申请职务', prop: 'apply_position_name', minWidth: 110 },
          { label: '对接人', prop: 'docking_name', minWidth: 110 },
          {
            label: '入会时间',
            prop: 'join_time',
            minWidth: 160,
            // sortable: 'custom'
          },
          { label: '数据类型', prop: 'data_type', minWidth: 100 },
          { label: '任务进度', prop: 'task', minWidth: 100 },
          {
            label: '最后登录时间',
            prop: 'last_login_time',
            minWidth: 160,
            // sortable: 'custom'
          },
          {
            type: 'operation',
            label: '操作',
            width: 120,
            operations: [
              { command: 'profile', text: '查看' },
              { command: 'more', text: '更多', operations: moreHandleList },
            ],
          },
        ],
        register: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '用户名称', prop: 'nickname', minWidth: 110 },
          { label: '性别', prop: 'gender_text' },
          { label: '籍贯', prop: 'native_place', minWidth: 100 },
          { label: '手机号码', prop: 'phones', minWidth: 150 },
          { label: '推荐人', prop: 'referee' },
          { label: '申请架构', prop: 'join_organization_name', minWidth: 110 },
          { label: '申请职务', prop: 'apply_position_name', minWidth: 110 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 180,
            // sortable: 'custom',
          },
          {
            type: 'operation',
            label: '操作',
            width: 200,
            operations: [
              { command: 'pass', text: '通过' },
              { command: 'no_pass', text: '不通过' },
              { command: 'profile', text: '个人资料' },
            ],
          },
        ],
        // 不通过记录
        nopass: [
          { label: 'ID', prop: 'id', minWidth: 80 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '用户名称', prop: 'name', minWidth: 110 },
          { label: '性别', prop: 'gender_text' },
          { label: '籍贯', prop: 'native_place', minWidth: 100 },
          { label: '手机号码', prop: 'phone', minWidth: 150 },
          { label: '推荐人', prop: 'referee' },
          { label: '申请架构', prop: 'organization', minWidth: 110 },
          { label: '申请职务', prop: 'position', minWidth: 110 },
          {
            label: '提交时间',
            prop: 'apply_time',
            minWidth: 200,
            sortable: 'custom',
          },
          {
            type: 'operation',
            label: '操作',
            width: 150,
            operations: [{ command: 'no_pass_reason', text: '不通过原因' }],
          },
        ],
      },
      schoolHead: {
        normal: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '用户名称', prop: 'nickname', minWidth: 110 },
          { label: '会内职务', prop: 'title_name', minWidth: 150 },
          { label: '会费状态', prop: 'pay_status_text', minWidth: 90 },
          { label: '年龄', prop: 'age_text' },
          { label: '性别', prop: 'gender_text' },
          { label: '民族', prop: 'national' },
          { label: '籍贯', prop: 'native_place', minWidth: 160 },
          { label: '政治面貌', prop: 'political_status' },
          { label: '手机号码', prop: 'phones', minWidth: 120 },
          { label: '班级', prop: 'student_infos', minWidth: 360 },
          {
            label: '认证状态',
            prop: 'status_text',
            minWidth: 120,
            type: 'slot',
          },
          { label: '协助认证', prop: 'assist_auth_name', minWidth: 120 },
          {
            label: '认证时间',
            prop: 'auth_time',
            minWidth: 180,
          },
          { label: '推荐人', prop: 'referee', minWidth: 120 },
          {
            label: '入会时间',
            prop: 'join_time',
            minWidth: 160,
          },
          { label: '数据类型', prop: 'data_type', minWidth: 120, type: 'slot' },
          { label: '邀请人数', prop: 'invite_count', minWidth: 120 },
          {
            label: '最后登录时间',
            prop: 'last_login_time',
            minWidth: 180,
          },
          {
            label: '操作',
            type: 'operation',
            operations: [
              { command: 'profile', text: '查看' },
              {
                command: 'more',
                text: '更多',
                operations: moreHandleList,
                disabled: (row) => row.status !== 1,
              },
            ],
            width: 120,
          },
        ],
        register: [
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '用户名称', prop: 'nickname', minWidth: 110 },
          { label: '年龄', prop: 'age_text' },
          { label: '性别', prop: 'gender_text' },
          { label: '籍贯', prop: 'native_place', minWidth: 120 },
          { label: '手机号码', prop: 'phones', minWidth: 150 },
          { label: '班级', prop: 'student_infos', minWidth: 400, type: 'slot' },
          {
            label: '校友会名称',
            prop: 'join_organization_name',
            minWidth: 110,
          },
          { label: '申请职务', prop: 'apply_position_name', minWidth: 110 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 160,
            // sortable: true,
          },
          {
            type: 'operation',
            label: '操作',
            width: 200,
            operations: [
              { command: 'pass', text: '通过' },
              { command: 'no_pass', text: '不通过' },
              { command: 'profile', text: '个人资料' },
            ],
          },
        ],
        // 不通过记录
        nopass: [
          { label: 'ID', prop: 'id', minWidth: 80 },
          { label: '头像', prop: 'avatar', minWidth: 100 },
          { label: '用户名称', prop: 'name', minWidth: 110 },
          { label: '年龄', prop: 'age_text' },
          { label: '性别', prop: 'gender_text' },
          { label: '手机号码', prop: 'phone', minWidth: 150 },
          { label: '班级', prop: 'class_info', minWidth: 400 },
          { label: '校友会名称', prop: 'organization', minWidth: 110 },
          { label: '申请职务', prop: 'position', minWidth: 110 },
          {
            label: '提交时间',
            prop: 'apply_time',
            minWidth: 200,
            sortable: 'custom',
          },
          {
            type: 'operation',
            label: '操作',
            width: 100,
            operations: [{ command: 'no_pass_reason', text: '不通过原因' }],
          },
        ],
      },
      unitHead: {
        formal: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '会员编号', prop: 'member_number', minWidth: 100 },
          { label: '单位logo', prop: 'avatar', minWidth: 100 },
          { label: '单位名称', prop: 'nickname', minWidth: 220 },
          { label: '成立时间', prop: 'start_date', minWidth: 180 },
          { label: '单位地址', prop: 'address', minWidth: 250 },
          { label: '授权代表人', prop: 'unit_username', minWidth: 100 },
          { label: '联系电话', prop: 'phones', minWidth: 130 },
          { label: '会内职务', prop: 'title_name', minWidth: 150 },
          { label: '会费状态', prop: 'pay_status_text' },
          { label: '推荐人', prop: 'referee' },
          { label: '入会时间', prop: 'join_time', minWidth: 160, sortable: 'custom' },
          { label: '数据类型', prop: 'data_type', type: 'slot', minWidth: 100 },
          { label: '邀请人数', prop: 'invite_count', minWidth: 100 },
          {
            label: '最后登录时间',
            prop: 'last_login_time',
            minWidth: 160,
            sortable: 'custom'
          },
          {
            type: 'operation',
            label: '操作',
            width: 120,
            operations: [
              { command: 'profile', text: '查看' },
              {
                command: 'more',
                text: '更多',
                operations: [
                  { name: '我的企业', command: 'UnitCompany' },
                  { name: '我的资源', command: 'UnitResources' },
                  { name: '我的活动', command: 'UnitActivity' },
                  { name: '我的留言', command: 'UnitLeaveMsg' },
                  { name: '我的订单', command: 'UnitOrder' },
                  { name: '职务记录', command: 'UnitPositionRecode' },
                  { name: '被举报记录', command: 'UnitReportRecode' },
                  // { name: "查看转账凭证 ", command: "myCredential" },
                  { name: '转账记录', command: 'UnitCredential' },
                  { name: '邀请收益', command: 'UnitInvitationRevenue' },
                  { name: '等级积分记录', command: 'UnitIncomeRecords' },
                  { name: '赠送积分', command: 'AddIntegral' },
                  { name: '分配成长值', command: 'Experience' },
                  { name: '转为个人会员', command: 'TransformRole' },
                  {
                    name: '退会',
                    command: 'Exit',
                    visible: (row) => {
                      return (
                        row.userPosition.length > 0 &&
                        row.userPosition[0].position_name
                      )
                    },
                  },
                ],
              },
            ],
          },
        ],
        pre: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '会员编号', prop: 'member_number', minWidth: 100 },
          { label: '单位logo', prop: 'avatar', minWidth: 100 },
          { label: '单位名称', prop: 'nickname', minWidth: 110 },
          { label: '授权代表人', prop: 'unit_username', minWidth: 100 },
          { label: '联系电话', prop: 'phones', minWidth: 120 },
          { label: '会内职务', prop: 'title_name', minWidth: 150 },
          { label: '会费状态', prop: 'pay_status_text', minWidth: 90 },
          { label: '推荐人', prop: 'referee', minWidth: 110 },
          { label: '申请架构', prop: 'join_organization_name', minWidth: 110 },
          { label: '申请职务', prop: 'apply_position_name', minWidth: 110 },
          { label: '对接人', prop: 'docking_name', minWidth: 110 },
          {
            label: '入会时间',
            prop: 'join_time',
            minWidth: 160,
            sortable: 'custom'
          },
          { label: '数据类型', prop: 'data_type', minWidth: 100 },
          { label: '任务进度', prop: 'task', minWidth: 100 },
          {
            label: '最后登录时间',
            prop: 'last_login_time',
            minWidth: 160,
            sortable: 'custom'
          },
          {
            type: 'operation',
            label: '操作',
            width: 120,
            operations: [
              { command: 'profile', text: '查看' },
              {
                command: 'more',
                text: '更多',
                operations: [
                  { name: '我的企业', command: 'UnitCompany' },
                  { name: '我的资源', command: 'UnitResources' },
                  { name: '我的活动', command: 'UnitActivity' },
                  { name: '我的留言', command: 'UnitLeaveMsg' },
                  { name: '我的订单', command: 'UnitOrder' },
                  { name: '职务记录', command: 'UnitPositionRecode' },
                  { name: '被举报记录', command: 'UnitReportRecode' },
                  // { name: "查看转账凭证 ", command: "myCredential" },
                  { name: '转账记录', command: 'UnitCredential' },
                  { name: '邀请收益', command: 'UnitInvitationRevenue' },
                  { name: '等级积分记录', command: 'UnitIncomeRecords' },
                  { name: '赠送积分', command: 'AddIntegral' },
                  { name: '分配成长值', command: 'Experience' },
                  { name: '转为个人会员', command: 'TransformRole' },
                  {
                    name: '退会',
                    command: 'Exit',
                    visible: (row) => {
                      return (
                        row.userPosition.length > 0 &&
                        row.userPosition[0].position_name
                      )
                    },
                  },
                ],
              },
            ],
          },
        ],
        register: [
          { type: 'selection' },
          { label: 'ID', prop: 'user_id', minWidth: 100 },
          { label: '单位logo', prop: 'avatar', minWidth: 100 },
          { label: '单位名称', prop: 'nickname', minWidth: 110 },
          { label: '授权代表人', prop: 'unit_username', minWidth: 100 },
          { label: '联系电话', prop: 'phones', minWidth: 150 },
          { label: '推荐人', prop: 'referee' },
          { label: '申请架构', prop: 'join_organization_name', minWidth: 110 },
          { label: '申请职务', prop: 'apply_position_name', minWidth: 110 },
          {
            label: '提交时间',
            prop: 'create_time',
            minWidth: 180,
            sortable: 'custom',
          },
          {
            type: 'operation',
            label: '操作',
            width: 200,
            operations: [
              { command: 'pass', text: '通过' },
              { command: 'no_pass', text: '不通过' },
              { command: 'profile', text: '个人资料' },
            ],
          },
        ],
        // 不通过记录
        nopass: [
          { label: 'ID', prop: 'id', minWidth: 100 },
          { label: '单位logo', prop: 'avatar', minWidth: 100 },
          { label: '单位名称', prop: 'name', minWidth: 110 },
          { label: '授权代表人', prop: 'unit_username', minWidth: 100 },
          { label: '联系电话', prop: 'phone', minWidth: 150 },
          { label: '推荐人', prop: 'referee' },
          { label: '申请架构', prop: 'organization', minWidth: 110 },
          { label: '申请职务', prop: 'position', minWidth: 110 },
          {
            label: '提交时间',
            prop: 'apply_time',
            minWidth: 200,
            sortable: 'custom',
          },
          {
            type: 'operation',
            label: '操作',
            width: 120,
            operations: [{ command: 'no_pass_reason', text: '不通过原因' }],
          },
        ],
      },
      // 筛选对象
      filterForm: {
        start_time: -1,
        end_time: -1,
        start_join_time: -1,
        end_join_time: -1,
        member_number: '',
        position_id: -1, // 职务id
        organization_id: -1, // 申请架构id
        age: -1, // 年龄
        gender: -1, // 性别
        native_place: '', //籍贯
        political_status: '', // 政治面貌
        national: '', // 民族
        data_type: -1, // 数据类型
        credentials: -1, // 转账凭证
        pay_status: -1, // 会费状态
        keyword: '', //关键词
        tab: 'formal', //当前筛选tab
        page_size: 15, //每页多少条数据
        positionIds: [], // 会内职务
        apply_position_ids: [], // 申请职务
        phone: '', // 手机号码
        entrance_year: -1, // 毕业年份
        period_id: '', // 学段id
        campus_id: '', // 校区id
        class_name: '', // 班级名称
        teacher: '', // 班主任
        status: -1, // 认证状态
        assist_auth_name: '', // 协助认证
        name: '', // 用户名称
        page: 1,
        order_by: 'create_time',
        is_desc: 1
      },
      // 不通过
      pageData: {
        page_size: 15,
        tab: 'formal',
      },
      current_check_type: 0,
      openReason: false,
      openExit: false,
      isShowReason: false,
      isShowSelect: false, // 是否显示选择组织
      isShowImportMembers: false,
      isShowImportUnits: false,
      Ids: [],
      // tbody: [],
      PositionList: [], // 用户架构列表
      isShowAdd: false,
      noPassForm: {},

      transformLoading: false,

      /* 会费状态修改 */
      isOpenChangePayStatus: false,
      // 缴费状态
      StatusPayForm: {
        id: 0,
        pay_status: 0,
        pay_time: null,
      },
      changeLogs: '',
      isComplete: false
    }
  },
  computed: {
    ...mapState('socket', ['messagesData']),
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  // watch: {
  //   activeName() {
  //     this.$nextTick(() => {
  //       this.refreshList()
  //     })
  //   },
  // },
  methods: {
    initLayout() {
      // const refName = this.getRefName()
      // if (this.$refs[refName]) this.$refs[refName].mixin__created()
    },
    clickTransformMany(idList = []) {
      if (!idList.length) {
        this.$message.info('没有检测到勾选的数据！')
        return
      }
      if (idList.length > 1) this.transformLoading = true
      changeApplyType(idList)
        .then((res) => {
          this.$message.success(res.msg)
          this.refreshList()
          this.transformLoading = false
        })
        .catch((err) => {
          this.transformLoading = false
        })
    },
    tryChangePayStatus(form) {
      const { pay_status, pay_time } = form
      const formattedForm = {
        pay_status,
      }
      if (pay_status === 1) {
        // 已缴费才有时间字段
        if (pay_time > 0) {
          formattedForm.pay_time = pay_time / 1000
        } else {
          // 其他可能为 null, ''
          formattedForm.pay_time = 0
        }
      } else {
        formattedForm.pay_time = 0
      }

      let change_list = []
      this._idList.forEach(id => {
        // 此 id 为 user_id
        const { userPosition, ...user } =
            this._tbody.find((t) => t.id === id) || {}

        change_list.push(...userPosition.map(u => ({
          form: {id: u.user_position_id, ...formattedForm}, // 给接口的
          log: {...user, title_name: u.title_name}, // 给前端展示的
        })))
      })

      this.handleChangeFee(change_list)
    },
    handleFee(idList, tbody) {
      if (!idList.length) {
        this.$message.info('没有检测到勾选的数据！')
        return
      }
      this.isOpenChangePayStatus = true
      this.changeLogs = ''
      this.isComplete = false
      this._idList = idList
      this._tbody = tbody
    },
    handleChangeFee(arr) {
      this.loading = this.$loading({
        lock: true,
        text: '正在批量操作……，进度 0 %',
      })

      let that = this
      manager = new AQM({ maxParallel: 3 })

      for (let i = 0; i < arr.length; i++) {
        try {
          manager.push(() => {
            const currentLine = arr[i].log;

            return changeFeeStatus(arr[i].form)
              .then((err) => {
                that.changeLogs += `【${currentLine?.nickname}】的【${currentLine?.title_name}】缴费状态从【${
                  currentLine.pay_status_text
                }】修改至【${
                  ['未缴费', '已缴费', '无需缴费'][arr[i].form.pay_status]
                }】，结果：<span style="color: green;">${err.msg}</span>\n\n`
              })
              .catch((err) => {
                that.changeLogs += `【${currentLine?.nickname}】的【${currentLine?.title_name}】缴费状态从【${
                  currentLine.pay_status_text
                }】修改至【${
                  ['未缴费', '已缴费', '无需缴费'][arr[i].form.pay_status]
                }】，结果：<span style="color:red;">${err.msg}</span>\n\n`
              })
          })
        } catch (e) {
          console.log(e)
        }
      }
      this.progress = 0
      manager
        .start(function (res) {
          // 每一次请求成功的回调
          const percentage = ((that.progress * 100) / arr.length).toFixed(0)
          that.loading.setText(`正在批量操作……，进度 ${percentage} %`)
        })
        .then(() => {
          this.loading.close()
          this.refreshList()
        })
        .catch((err) => {
          console.log(err, 'queue error!')
          this.loading.close()
          this.refreshList()
        }).finally(() => {
          this.isComplete = true
      })
    },
    handleQuit(idList, tbody) {
      let that = this
      if (!idList.length) {
        this.$message.info('没有检测到勾选的数据！')
        return
      }
      const positionUserList = []
      const userList = []
      tbody.forEach((t) => {
        if (idList.includes(t.id)) {
          userList.push(t)
        }
      })
      userList.forEach((el) => {
        //   if (el.userPosition && el.userPosition.length) {
        //     el.userPosition.forEach((p) => {
        //       if (p.user_position_id) positionUserList.push(p.user_position_id)
        //     })
        //   }
        positionUserList.push(el.user_position_id) // 可能是多职务
      })
      if (!positionUserList.length) {
        this.$message.info('没有用户需要退会')
        return
      }
      let is_del_user_data = false
      this.$confirm(
        '确定要将这些用户退会吗，请谨慎操作！<br/>' +
          '<input type="checkbox" style="vertical-align: middle;" id="is_del_user_data_queue" />删除产生的评论、内容发布等数据',
        '操作确认',
        {
          type: 'error',
          dangerouslyUseHTMLString: true,
          beforeClose: (action, instance, done) => {
            is_del_user_data =
              document.getElementById('is_del_user_data_queue')?.checked ||
              false
            if (action === 'confirm') {
              this.openFullScreen()
              this.progress = 0

              manager = new AQM({ maxParallel: 3 })

              for (let i = 0; i < positionUserList.length; i++) {
                try {
                  manager.push(() => {
                    return ExitOrganization(
                      {
                        id: positionUserList[i],
                        is_del_user_data: is_del_user_data ? 1 : 0,
                      },
                      true
                    )
                  })
                } catch (e) {
                  console.log(e)
                }
              }
              // let resultList = []
              manager
                .start(function (res) {
                  // 每一次请求成功的回调
                  that.progress++
                  const percentage = (
                    (that.progress * 100) /
                    positionUserList.length
                  ).toFixed(0)
                  that.loading.setText(`正在批量退会……，进度 ${percentage} %`)
                })
                .then(() => {
                  this.loading.close()
                  this.refreshList()
                })
                .catch(() => {
                  this.loading.close()
                  this.refreshList()
                })
            }
            done()
          },
        }
      )
    },
    openFullScreen() {
      this.loading = this.$loading({
        lock: true,
        text: '正在批量退会……，进度 0 %',
      })
    },
    // 获取列表
    getMemberList(pageData) {
      if (this.filterForm.tab === 'nopass') {
        /* 不通过记录 */
        return rejectList({ ...pageData, pageset: this.activeName })
      }
      if (this.tenantVersion === 'school') {
        if (this.filterForm.tab !== 'register') {
          /* 校友列表【校友版】 */
          return ClassMembers(pageData)
        }
      }
      /* 会员列表【普通版】、审核列表【校友版】、申请入会用户列表【普通版】 */
      return new Promise(resolve => {
        memberList({ ...pageData, pageset: this.activeName }).then((res) => {
          resolve({
            ...res,
            data: {
              ...res.data,
              data: this.handleMemberList(res.data.data || [])
            }
          })
        })
      })
    },
    // 处理会员列表数据
    handleMemberList(tbody) {
      const _tbody = JSON.parse(JSON.stringify(tbody))
      let arr = [] // 用于存储表格合并所需数据
      let r = 0
      let table = [] // 用于存储会员列表处理后数据
      /* 否则 userPosition 为 [] 导致数据不显示【只有校友版会出现空的情况】 */
      _tbody.forEach((item) => {
        if (!item.userPosition.length) {
          item.userPosition.push({})
        }
      })
      _tbody.forEach((item, i, data) => {
        if (arr.length) {
          r = arr[arr.length - 1].start_row_index + (data[i - 1].userPosition.length || 1)
        }
        arr.push({
          user_id: item.user_id, // 用户id，调试用
          nickname: item.nickname, // 调试用
          start_row_index: r, // data index，一个人开始的行索引
          span: item.userPosition.length || 1, // rowspan，同一个人，跨越多行
        })
        if (item.userPosition && item.userPosition.length) {
          item.userPosition.forEach((userItem) => {
            table.push({ ...item, ...userItem, user_id: item.user_id }) // 原来的后端一行，变成n行（n个职务）
          })
        }
      })
      // console.log(arr.map(el => el.nickname + '::' + el.user_id))
      // console.log(table.map(el => el.nickname + '::' + el.user_position_id))
      this.spanArr = []
      this.spanArr.push(...arr)
      return table
    },
    // 表格合并操作
    arraySpanMethod(e) {
      switch (this.filterForm.tab) {
        case 'normal':
        case 'formal':
          // 这些属性是不需要合并的
          if (
            !['title_name', 'pay_status_text', 'referee', 'join_time'].includes(
              e.column.property
            )
          ) {
            let obj = [0, 0] // 行，列
            this.spanArr.some((item) => {
              if (e.rowIndex === item.start_row_index) {
                obj = [item.span, 1] // 占用 n 行，1 列
                // obj = {
                //   rowspan: item.index,
                //   colspan: 1
                // } // 另一种写法也可以
              }
            })
            return obj
          }
          break
        case 'pre':
          if (
            ![
              'title_name',
              'pay_status_text',
              'referee',
              'join_organization_name',
              'apply_position_name',
              'docking_name',
              'join_time',
            ].includes(e.column.property)
          ) {
            let obj = [0, 0]
            this.spanArr.some((item) => {
              if (e.rowIndex === item.start_row_index) {
                obj = [item.span, 1]
              }
            })
            return obj
          }
          break
        case 'register':
          if (
            ![
              'referee',
              'join_organization_name',
              'apply_position_name',
              'create_time',
            ].includes(e.column.property)
          ) {
            let obj = [0, 0]
            this.spanArr.some((item) => {
              if (e.rowIndex === item.start_row_index) {
                obj = [item.span, 1]
              }
            })
            return obj
          }
          break
      }
    },
    // 添加会员
    AddMember(arr) {
      const data = {}
      data.id = 0
      data.uid = arr[0].id
      // data.type = this.filterForm.tab;
      // data.status = '';
      this.$router.push({
        name: 'AddMember',
        params: { data: this.$enCode(data) },
      })
    },
    addUnit(arr) {
      this.$router.push({
        name: 'AddUnit',
        params: {
          user_id: 0,
        },
        query: {
          cid: arr[0].id,
        },
      })
    },
    //导入会员
    importMember() {},
    // 查看会员
    CheckRow(row, type) {
      const data = {}
      data.id = row.user_id || row.id
      data.type = type
      if (row.class_id) data.class_id = row.class_id
      data.status =
        type === 'formal' || type === 'normal' ? 1 : type === 'pre' ? 2 : ''
      if (
        this.tenantVersion === 'school' &&
        row.status !== 1 &&
        type === 'normal'
      ) {
        data.nickname = row.nickname
        this.$router.push({
          name: 'EditUncertified',
          params: { id: row.student_id },
        })
      } else if (this.activeName === 'unit') {
        this.$router.push({
          name: 'EditUnit',
          params: {
            id: row.id,
            user_id: row.user_id,
          },
        })
      } else {
        this.$router.push({
          name: 'EditMember',
          params: { data: this.$enCode(data) },
        })
      }
    },
    // 点击下拉菜单
    handleClickRow({ command: value, row }) {
      switch (value) {
        case 'TransformRole': {
          // TODO: 转为个人会员（单个）
          this.clickTransformMany([row.id])
          break
        }
        case 'AddIntegral': {
          // 赠送积分弹窗
          this.user = row
          this.isShowAddIntegral = true
          break
        }
        case 'pass':
          this.PassRow(row, 1)
          break
        case 'no_pass':
          this.PassRow(row, 0)
          break
        case 'no_pass_reason':
          this.noPassRow(row)
          break
        case 'profile':
          this.CheckRow(row, this.filterForm.tab)
          break
        case 'Exit':
          this[value](row)
          break
        case 'changeJoinTime':
          this.openDialog = true
          this.user_position_id = row.user_position_id
          break
        case 'Experience':
          console.log(row)
          this.experienceDialog.user_id = row.user_id
          this.experienceDialog.show = true
          break
        default: {
          const data = {}
          data.id = row.user_id || row.id
          data.type = this.filterForm.tab
          data.status =
            data.type === 'formal' || data.type === 'normal'
              ? 1
              : data.type === 'pre'
              ? 2
              : ''
          if (this.activeName === 'unit') {
            console.log(value, row.user_id)
            this.$router.push({
              name: value,
              params: { user_id: row.user_id },
            })
            return
          }
          this.$router.push({
            name: value,
            params: { data: this.$enCode(data) },
          })
        }
      }
    },
    // 退会
    Exit(row) {
      if (row.userPosition.length > 1) {
        this.PositionList = row.userPosition
        this.openExit = true
      } else {
        let is_del_user_data = false
        this.$msgbox.confirm(
          '确定要退会吗？请谨慎操作<br/>' +
            '<input type="checkbox" style="vertical-align: middle;" id="is_del_user_data" />删除产生的评论、内容发布等数据',
          '提示',
          {
            type: 'error',
            dangerouslyUseHTMLString: true,
            beforeClose: (action, instance, done) => {
              is_del_user_data =
                document.getElementById('is_del_user_data')?.checked || false
              if (action === 'confirm') {
                const loading = this.$loading({
                  lock: true,
                  text: '正在处理退会……',
                })
                let id = row.userPosition[0].user_position_id
                ExitOrganization({
                  id,
                  is_del_user_data: is_del_user_data ? 1 : 0,
                })
                  .then(() => {
                    loading.close()
                    this.$message.success('退会成功')
                    this.refreshList()
                  })
                  .catch(() => {})
              }
              done()
            },
          }
        )
      }
    },
    // 切换tab栏
    handleClick() {
      this.filterForm = {
        ...JSON.parse(this.resetFormJson),
        tab: this.filterForm.tab,
      }
      const refName = this.getRefName()
      this.$refs[refName].getList(this.filterForm)
    },
    getRefName() {
      // return this.tenantVersion === 'school'
      //   ? (this.filterForm.tab === 'register' ? 'schoolRegisterList' : 'schoolMateList')
      //   : 'memberList'
      if (this.tenantVersion === 'school') return 'schoolMateList'
      return this.activeName === 'unit' ? 'unitList' : 'memberList'
      // return 'memberList'
    },
    // 批量通过/不通过
    handlePass(status, tab, selectArr) {
      let ids = []
      const refName = this.getRefName()
      if (selectArr.length) {
        selectArr.map((ArrItem) => {
          this.$refs[refName].tbody.map((item) => {
            if (item.id === ArrItem) {
              ids.push(item.user_position_id)
            }
          })
        })
        this.Ids = ids
        this.isShowSelect = false
        this.current_check_type = status
        this.openReason = true
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表通过/不通过
    PassRow(row, status) {
      if (row.userPosition.length > 1) {
        this.isShowSelect = true
        this.Ids = []
        this.PositionList = row.userPosition
      } else {
        this.Ids = [row.userPosition[0].user_position_id]
        this.isShowSelect = false
      }
      this.current_check_type = status
      this.openReason = true
    },
    noPassRow(row) {
      this.noPassForm = row
      this.isShowReason = true
    },
    //审核通过操作
    pass(data) {
      Checked(data)
        .then((res) => {
          this.refreshList()
        })
        .catch((err) => {})
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      const refName = this.getRefName()
      this.$refs[refName].getList(this.filterForm)
    },
    // 刷新列表
    refreshList() {
      const refName = this.getRefName()
      this.$refs[refName]?.getList({ page: 1 })
    },
    changeQuery(e) {
      this.$router.push({
        name: 'CycUserList',
        params: { tab: this.filterForm.tab },
        query: { ...this.$route.query, role: e.paneName },
      })
    },
    // 自定义事件
    hideJoinTimeDialog(val) {
      if (val == false) {
        this.openDialog = val
      } else {
        this.refreshList()
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const refName = this.getRefName()
    if (!this.$refs[refName]) return
    this.$refs[refName].mixin__beforeRouteLeave(to, from, next) // 转发给内部
  },
  created() {
    this.filterForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.filterForm.tab

    this.resetFormJson = JSON.stringify(this.filterForm)

    getRoleSetting()
      .then((res) => {
        this.thirdNavConfig = res.data
        this.activeName =
          this.$route.query.role ||
          (res.data.personal_user_enable ? 'user' : 'unit')
      })
      .catch(() => {})
  },
  components: {
    ChangePayStatus,
    AddIntegral,
    EnterpriseSelector,
    AddUnitDialog,
    NotMemberSelector,
    AddMemberDialog,
    ListImage,
    UnitFilter,
    RouterTabs,
    AddStudent,
    ListStatusText,
    ListFilterSchool,
    ListFilterRegister,
    ListLayout,
    listFilter,
    ReasonDialog,
    Exit,
    importMembers,
    importUnits,
    NopassReason,
    ChangeJoinTime,
    ExperienceDialog,
  },
}
</script>

<style lang="scss" scoped>
.third-nav {
  margin-bottom: $space;
}
</style>
