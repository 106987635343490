import api from '@/base/utils/request';

export const getTermConfig = () => {
  return api({
    url: '/admin/cyc/settings/getCycTermConfig',
    method: 'post'
  })
}

export const saveTermConfig = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycTermConfig',
    method: 'post',
    data
  })
}

// export const getMemberConfig = () => {
//   return api({
//     url: '/admin/cyc/settings/getCycMemberConfigConfig',
//     method: 'post'
//   })
// }
//
// export const saveMemberConfig = (data) => {
//   return api({
//     url: '/admin/cyc/settings/setCycMemberConfigConfig',
//     method: 'post',
//     data
//   })
// }

export const getDockingConfig = () => {
  return api({
    url: '/admin/cyc/settings/getCycDockingConfig',
    method: 'post'
  })
}

export const saveDockingConfig = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycDockingConfig',
    method: 'post',
    data
  })
}

export const getInvitationCodeConfig = () => {
  return api({
    url: '/admin/cyc/settings/getCycInvitationCodeConfig',
    method: 'post'
  })
}

export const saveInvitationCodeConfig = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycInvitationCodeConfig',
    method: 'post',
    data
  })
}

export const getShowConfig = () => {
  return api({
    url: '/admin/cyc/settings/getCycOrganizationShowConfig',
    method: 'post'
  })
}

export const saveShowConfig = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycOrganizationShowConfig',
    method: 'post',
    data
  })
}

/**
 * 联系方式
 */
export const getContact = () => {
  return api({
    url: '/admin/cyc/settings/getCycRegisterContactConfig',
    method: 'post'
  })
}

export const saveContact = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycRegisterContactConfig',
    method: 'post',
    data
  })
}

/**
 * 加入群聊
*/
export const getJoinGroupChat = () => {
  return api({
    url: '/admin/cyc/settings/getCycGroupChatConfig',
    method: 'post'
  })
}

export const saveJoinGroupChat = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycGroupChatConfig',
    method: 'post',
    data
  })
}

export const getRoleSetting = (data) => {
  return api({
    url: '/admin/cyc/settings/getCycApplyRoleConfig',
    method: 'post',
    data
  })
}

export const setRoleSetting = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycApplyRoleConfig',
    method: 'post',
    data
  })
}
