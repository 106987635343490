import { set_cptable } from 'xlsx'
import * as cptable from 'xlsx/dist/cpexcel.full.mjs'
import { runPromiseByQueue, sliceArrFn } from '@/base/utils/tool'
import JSZip from 'jszip' /* 读取 excel 图片数据 */
import * as XLSX from 'xlsx'
import X2JS from 'x2js' /* 用来解析 xml 文件 */
import filesUpload from '@/base/utils/upload4'

set_cptable(cptable)

/**
 * excel 转 json
 * @param file
 * @param suc
 * @param fai
 */
function _readXlsxFileData(file, suc, fai) {
  // 通过FileReader对象读取文件
  const fileReader = new FileReader()
  fileReader.onload = (event) => {
    try {
      const { result } = event.target
      // 以二进制流方式读取得到整份excel表格对象
      const workbook = XLSX.read(result, { type: 'binary' })
      let data = [] // 存储获取到的数据
      // 遍历每张工作表进行读取（这里默认只读取第一张表）
      for (const sheet in workbook.Sheets) {
        if (workbook.Sheets.hasOwnProperty(sheet)) {
          // 利用 sheet_to_json 方法将 excel 转成 json 数据
          data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
          break // 如果只取第一张表，就取消注释这行
        }
      }

      // region *** 在这里写读取后的代码 *********
      // 打印数据查看
      console.log(data)

      let titles = []
      const list = []
      data.forEach((d, dI) => {
        if (dI > 0) {
          list.push(Object.keys(data[0]).map((k) => d[k] || null))
        } else {
          titles = Object.keys(d).map((k) => d[k] || null)
        }
      })

      // console.log(titles)
      // console.log(list)
      suc(data, titles, list)
    } catch (e) {
      fai(e)
    }
  }
  // 以二进制方式打开文件
  fileReader.readAsBinaryString(file)
}

/**
 * 入口
 * @param imageHeads
 * @param size      每次处理n条
 * @param file
 * @param success
 * @param fail
 * @param onImport  导入 Promise
 * @param onProgress
 */
export const readExcel = ({
  imageHeads,
  size,
  file,
  success,
  fail,
  onImport,
  onProgress,
}) => {
  try {
    _readXlsxFileData(
      file,
      (data, thead, tbody) => {
        console.log(data, '原始数据')

        const sliceArr = sliceArrFn(tbody, size) // 将数据切割成100条一份处理，得到二维数组
        const errMsgArr = []
        let count = 0

        const queue = runPromiseByQueue(
          sliceArr.map((s, sI) => {
            return () => {
              return new Promise(async (resolve) => {
                /**
                 * matchList1 格式：[a:b, c:d]
                 * matchList2 格式：[{key: b, val: 'https://xxxx'}, {key: d, val: null}]
                 */
                const [matchList1, matchList2] = await _getRelations({ file }) // 图片上传好了，准备在下面进行替换

                // 去掉了表头的数据
                s.forEach((row, rowI) => {
                  // console.log(rowI, row) // 用户要导入的数据，每一行

                  // 存放图片的列，如果为空就不处理上传。TODO：另外，为了防止重复上传，需要有缓存机制
                  imageHeads.forEach(async (img_row_title) => {
                    // 找到目标图片列在第几列：
                    const avatarI = thead.findIndex((t) =>
                      t.includes(img_row_title)
                    )

                    if (avatarI === -1) {
                      // 没有图片列，不需要处理
                    } else {
                      // 获取图片数据
                      const image = row[avatarI] // 图片列，参考格式：'=DISPIMG(\"ID_46DB4117288D4540A68BC24E1543CAEE\",1)'

                      if (image) {
                        // 提取id
                        const arr = image.match(/ID_[\d\w]+/)
                        if (arr) {
                          const ID = arr[0]
                          // 处理图片
                          const xl_img_name = matchList1[ID]
                          const xl_img_file =
                            matchList2.find((el) => el.key === xl_img_name)
                              ?.val || ''
                          if (!xl_img_file) return
                          const {
                            data: { url: xl_img_url },
                          } = await filesUpload({
                            // 上传配置获取api
                            configApi:
                              '/admin/admin/media/requestUpload' /* TODO：只适用于SaaS后台 */,
                            data: xl_img_file,
                            allowSlice: 1,
                            typeArr: ['image'],
                          })

                          // console.log(xl_img_name, xl_img_url)
                          s[rowI][avatarI] = xl_img_url // 改为 cos 链接
                        } else {
                          // 匹配不到，返回null
                        }
                      }
                    }
                  })
                })

                onImport({
                  titles: thead,
                  list: s,
                  // excel 里面 行号 3 才是真实数据开始
                  start_row: sI * size + 3,
                })
                  .then((res) => {
                    if (!res.data.status) {
                      /* 记录不同的导入失败原因 */
                      if (
                        !errMsgArr.find((errText) => errText === res.data.msg)
                      ) {
                        errMsgArr.push(res.data.msg)
                      }
                    }
                    count++
                    if (count === sliceArr.length) {
                      onProgress(100)
                    } else {
                      onProgress(Math.floor((count * 100) / sliceArr.length))
                    }
                    resolve()
                  })
                  .catch((err) => {
                    errMsgArr.push(err?.message || JSON.stringify(err))
                    resolve()
                  })
              })
            }
          })
        )

        Promise.all([queue])
          .then(() => {
            if (errMsgArr.length) {
              fail(errMsgArr)
            } else {
              success()
            }
          })
          .catch(() => {
            fail(errMsgArr)
          })
      },
      fail
    )
  } catch (e) {
    console.log(e)
  }
}

/**
 * 将 excel 内嵌图片解析为媒体库链接图片
 * @param file
 * @param data
 * @param thead
 * @param tbody
 * @returns {Promise<*[]>}
 * @private
 */
async function _getRelations({ file, data, thead, tbody }) {
  const imagesTarResult = {} // excel cellimage id
  let imageList = [] // 用来存放图片
  const zip = new JSZip() // 创建jszip实例

  try {
    let zipLoadResult = await zip.loadAsync(file) // 将xlsx文件转zip文件
    console.log('zipLoadResult', zipLoadResult)

    const result = zipLoadResult
    const imagesTarget = {}

    const template1 = result.files['xl/_rels/cellimages.xml.rels'], // 把 xxx.xlsx 改成 xxxx.zip 可知文件结构
      template2 = result.files['xl/cellimages.xml'] // 把 xxx.xlsx 改成 xxxx.zip 可知文件结构
    if (template1 && template2) {
      const xml = await template1.async('string')
      const xml2 = await template2.async('string')

      var x2js = new X2JS()

      let temp = x2js.xml2js(xml) //转为json格式
      let temp2 = x2js.xml2js(xml2) //转为json格式
      let Relationship = temp.Relationships.Relationship
      if (!Array.isArray(Relationship)) {
        Relationship = [Relationship]
      }
      Relationship.forEach((cell) => {
        imagesTarget[cell._Id] = cell._Target
      })
      let cellImage = temp2.cellImages.cellImage
      if (!Array.isArray(cellImage)) {
        cellImage = [cellImage]
      }
      cellImage.forEach((cell) => {
        imagesTarResult[cell.pic.nvPicPr.cNvPr._name] =
          imagesTarget[cell.pic.blipFill.blip[`_r:embed`]]
      })
    }

    /**
     * 关系映射？？
     * 得到的结构类似：
     * {
     *   ID_46DB4117288D4540A68BC24E1543CAEE: "media/image1.jpeg"
             ID_DC7F43FECA87420191D52B7A34372051: "media/image2.jpeg"
             ID_E68C7988975348218C18633C3A3DA7C5: "media/image1.jpeg"
     * }
     */
    // console.log(imagesTarResult, 333333)

    for (const key in zipLoadResult['files']) {
      // 遍历结果中的files对象

      if (key.indexOf('media/image') != -1 && !key.dir) {
        await zip
          .file(zipLoadResult['files'][key].name)
          .async('blob')
          .then(async (res) => {
            // imageList.push(res) // 将解析出的图片的base64编码值 先存入imageList数组中；
            // console.log(res) // 注意：res.type 返回是空的

            // Blob 转 File
            const blobToFile = (blob, fileName) => {
              return new File([blob], fileName, {
                type: blob.type || `image/${getFileExt(fileName)}`,
              })
            }

            const getFileExt = (fileName) => {
              return (
                (fileName &&
                  fileName.split('.').length &&
                  fileName.split('.')[1]) ||
                ''
              )
            }

            imageList.push({
              key: zipLoadResult['files'][key].name.replace('xl/', ''),
              val: blobToFile(
                res,
                `js-zip-${zipLoadResult['files'][key].name.replace(/\//g, '_')}`
              ), // 先不上传
            })

            // await filesUpload({
            //   // 上传配置获取api
            //   configApi:
            //     '/admin/admin/media/requestUpload' /* TODO：只适用于SaaS后台 */,
            //   data: blobToFile(
            //     res,
            //     `js-zip-${zipLoadResult['files'][key].name.replace(/\//g, '_')}`
            //   ),
            //   allowSlice: 1,
            //   typeArr: ['image'],
            // })
            //   .then((res) => {
            //     // this.fileUrl = res.data.url
            //     imageList.push({
            //       key: zipLoadResult['files'][key].name.replace('xl/', ''),
            //       val: res.data.url,
            //     })
            //   })
            //   .catch((err) => {})
          })
      }
    }
  } catch (error) {
    console.log(error)
  }
  // console.log(imageList)
  /* 两对映射关系 */
  return [imagesTarResult, imageList]
}
