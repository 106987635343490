<!--
 * @Author: dongjia
 * @Date: 2021-08-05 13:45:19
 * @LastEditTime: 2021-09-23 17:44:32
 * @LastEditors: aleaner
 * @Description: 导入会员弹窗
 * @FilePath: \organization-admin\components\Member\ImportMembers.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    :title="handleSaaSVersionKeyword(`批量导入会员`)"
    :visible.sync="openDialog"
    :width="'600px'"
    class="dialog-vertical"
    close-on-click-modal
    close-on-press-escape
  >
    <div class="dialog-content" :class="[tenantVersion]">
      <div class="content-title" v-if="tenantVersion !== 'school'">
        请按以下步骤操作：
      </div>
      <div v-else class="content-title">说明：</div>
      <template v-if="tenantVersion !== 'school'">
        <div class="content-step">
          步骤1：下载《批量导入{{
            handleSaaSVersionKeyword('会员')
          }}模板》，按模板中的说明进行填写。
          <el-button
            @click="downloadExcel"
            class="download-btn"
            style="margin-left: 10px"
            type="text"
            >下载模板</el-button
          >
        </div>
        <div class="content-step">
          步骤2：上传文件后系统会自动进行批量导入{{
            handleSaaSVersionKeyword('会员')
          }}。
        </div>
      </template>
      <template v-else>
        <div class="content-step">
          1、导入的信息包含有手机号码、班级信息的请下载《批量导入已认证校友模板》，按模板中的说明进行填写。<el-link
            :underline="false"
            @click="downloadExcel"
            target="_blank"
            type="primary"
            >下载模板</el-link
          >
        </div>
        <div class="content-step">
          2、导入的信息只知道班级信息，不清楚手机号码的请下载《批量导入未认证校友模板》，按模板中的说明进行填写。<el-link
            :href="downloadUrl"
            :underline="false"
            target="_blank"
            type="primary"
            >下载模板</el-link
          >
        </div>
        <div class="content-step">
          3、上传《批量导入已认证校友模板》文件后系统会自动进行批量导入已认证的校友信息，届时用户通过导入的「手机号码」进行登录。
        </div>
        <div class="content-step">
          4、上传《批量导入未认证校友模板》文件后系统会自动进行批量导入未认证的校友信息，届时用户需要手动申请认证校友身份
        </div>
      </template>
    </div>

    <!--    <template v-if="excelData.length">-->
    <!--      <el-table class="thead-light" stripe :data="excelData.slice(1)" show-overflow-tooltip>-->
    <!--        <template v-for="(ex, exI) in Object.keys(excelData[0])">-->
    <!--          <el-table-column :label="excelData[0][ex]" width="150px"-->
    <!--                           :prop="ex" />-->
    <!--        </template>-->
    <!--      </el-table>-->
    <!--    </template>-->

    <div slot="footer">
      <div class="footer-btn">
        <el-button size="small" @click="openDialog = false">关闭</el-button>
        <el-upload
          :disabled="loading"
          :http-request="customHandler"
          :limit="1"
          :show-file-list="false"
          accept=".xlsx, .xls"
          action="#"
          ref="excelExplorer"
          v-if="tenantVersion !== 'school'"
        >
          <el-button
            :loading="loading"
            icon="el-icon-upload2"
            size="small"
            style="min-width: 100px"
            type="primary"
            >{{
              loading ? (progress ? `${progress}%` : '导入中') : '上传文件'
            }}</el-button
          >
        </el-upload>
        <template v-else>
          <UploadFileButton
            :on-custom-upload="handleNotCertified"
            :open="openDialog"
            :update="update"
            button-text="上传未认证校友文件"
            style="margin-left: 20px"
          />
          <UploadFileButton
            :open="openDialog"
            :update="update"
            button-text="上传已认证校友文件"
            style="margin-left: 20px"
          />
        </template>
        <!--        <UploadFileButton style="margin-left: 20px;" :open="openDialog" :update="update" button-text="上传文件" />-->
      </div>
    </div>

    <!-- 可以看看解析的效果 -->
    <el-dialog
      :center="true"
      :fullscreen="true"
      :title="!loading ? '导入' : '正在导入...'"
      :visible="loading"
      append-to-body
      v-if="false"
    >
      <el-table
        :data="list"
        :height="tableH"
        class="thead-light"
        v-if="list.length && tableH"
      >
        <el-table-column
          :key="title"
          :label="title"
          :width="200"
          v-for="(title, titleI) in titles"
        >
          <template slot-scope="{ row }">
            <!--            {{ imgRowTitles.filter(rowTitle => title.includes(rowTitle)) }}-->
            <template
              v-if="
                imgRowTitles.filter((rowTitle) => title.includes(rowTitle))
                  .length
              "
            >
              <list-image :is-image="true" :src="row[titleI] || ''" />
            </template>
            <template v-else>
              {{ row[titleI] }}
            </template>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-dialog>
</template>

<script>
import UploadFileButton from './UploadFileButton.vue'
import {
  importByObject,
  importStudents,
  MemberListExample,
} from '../../api/member/member-list'
/* load the codepage support library for extended support with older formats  */

import filesUpload from '../../../../base/utils/upload4'
import ListImage from '../../../../base/components/List/ListImage'
import { readExcel } from '../../../../base/utils/excel-handler'

export default {
  components: { ListImage, UploadFileButton },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      detailForm: null,
      templateFileOne: '',
      templateFileTwo: '',
      downloadUrl:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/files/批量导入未认证校友模板.xlsx',
      loading: false,
      progress: 0,

      titles: [],
      list: [],
      imgRowTitles: [],
      tableH: window.innerHeight * 0.9,
    }
  },
  methods: {
    customHandler(e) {
      this.loading = true

      readExcel({
        file: e.file,
        size: 100,
        imageHeads: [] || ['头像', '证件照正面图片链接', '证件照反面图片链接'],
        onProgress: (percent) => {
          this.progress = percent
        },
        onImport: (params) => importByObject(params), /* 参数内部自己处理好了 */
        // onImport: ({thead, list, start_row}) => {
        //   console.log('********************')
        //   console.log('********************')
        //   console.log(thead)
        //   console.log(list)
        //   console.log(start_row)
        //   console.log('********************')
        // },
        success: (data, titles, list) => {
          this.titles = titles

          this.$refs.excelExplorer.clearFiles()

          this.$message.success('导入成功')
          this.loading = false
          this.update()
        },
        fail: (errList) => {
          this.loading = false
          this.$refs.excelExplorer.clearFiles()

          if (errList?.length) {
            this.$msgbox
              .confirm(errList.join('；<br/>'), '导入失败', {
                type: 'error',
                showCancelButton: false,
                dangerouslyUseHTMLString: true,
              })
              .then(() => {})
              .catch(() => {})
          }
        },
      })
    },
    handleNotCertified(data) {
      return importStudents(data)
    },
    // 下载导入会员文件模板
    downloadExcel() {
      MemberListExample()
    },
    // 导入完成操作
    update() {
      this.openDialog = false
      this.updateList()
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  watch: {
    open(newValue) {
      if (newValue) {
        this.loading = false
        this.progress = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content-title {
  line-height: 1;
  color: #3a3a3a;
}

.dialog-content.school {
  .content-step {
    display: block;
    margin-top: $space;
  }

  .el-link {
    vertical-align: baseline;
  }
}

.content-step {
  color: #3a3a3a;
  margin-top: 20px;

  .download-btn {
    padding: 0;
    margin-right: 10px;
  }
}
.footer-btn {
  display: flex;
  float: right;
  padding-bottom: 20px;
  .el-button {
    margin-left: 20px;
  }
}
</style>
<style>
.el-message-box__status {
  top: 12px;
}

.el-message-box__message {
  white-space: pre-wrap;
}

.el-message-box__content {
  overflow: auto;
  max-height: 70vh;
}
</style>
