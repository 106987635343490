<template>
  <el-dialog
    :visible.sync="openDialog"
    width="450px"
    :show-close="false"
    append-to-body
    class="dialog-vertical"
    @open="getExperience"
  >
    <el-form
      v-loading="loading"
      size="medium"
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
    >
      <p class="flex" style="line-height: 36px">
        <span style="width: 100px; text-align: right"> 现有成长值：</span>
        {{ getForm.experience }}
        <span style="width: 100px; text-align: right">目前等级：</span
        >{{ getForm.level }}
      </p>
      <el-form-item label="分配：">
        <el-radio-group v-model="form.op">
          <el-radio label="inc">赠送成长值</el-radio>
          <el-radio :disabled="getForm.experience<=0" label="dec">扣除成长值</el-radio>
        </el-radio-group></el-form-item
      >
      <el-form-item label="分配数量：" prop="experience" >
        <p>
          {{form.op==='inc'?'赠送':'扣除'}}
          <el-input
            type="number"
            v-model.number="form.experience"
            :class="form.experience === '' ? 'error' : ''"
          />
          成长值
        </p>
      </el-form-item>
      <el-form-item label="分配原因：" prop="reason">
        <el-input 
          type="textarea"
          maxlength="20"
          v-model="form.reason"
          rows="4"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="submit('form')">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getLevelAndExperience,
  changeExperience,
} from '../../api/member/member-list'
export default {
  props: ['openDialog', 'user_id'],
  data() {
    return {
      form: {
        op: 'inc', // inc：增加，dec：扣除
        experience: 100,
        reason: '',
      },
      getForm: {
        experience: 0,
        header_street: '',
        level: 0,
      },
      loading: false,
      rules: {
        experience: [
          { required: true, message: '请填写分配数量', trigger: 'blur' },
        ],
        reason: [{ required: true, message: '请填写分配原因', trigger: 'blur' }],
      },
    }
  },
  methods: {
    // 确认
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changeExperience({ user_id: this.user_id, ...this.form }).then(
            (res) => {
              console.log('res', res)
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
              }
            }
          )
          //   )
          this.$emit('hideDialog')
        } else {
          return false
        }
      })
    },
    getExperience() {
      this.form = {
        op: 'inc', // inc：增加，dec：扣除
        experience: 100,
        reason: '',
      }
      this.getForm = {
        experience: 0,
        header_street: '',
        level: 0,
      }
      this.loading = true
      getLevelAndExperience({ user_id: this.user_id }).then((res) => {
        console.log('res', res)
        this.loading = false
        this.getForm = res.data
      })
    },
    changeExperience() {
      changeExperience(this.form).then((res) => {})
    }, // 取消
    cancel() {
      this.$refs.form.resetFields();
      this.$emit('hideDialog', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  p {
    height: 24px;
  }
  p + p {
    margin-top: 20px;
  }

  .error {
    ::v-deep.el-input__inner {
      border-color: red;
      padding: 0px;
    }
  }
  .el-input {
    width: 100px;
    padding: 0px;
    input {
    }
  }
  ::v-deep.el-input__inner {
    padding: 10px;
  }
}
</style>
