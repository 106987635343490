<template>
  <el-dialog
    :visible.sync="showNormalDialog"
    class="dialog-vertical"
    close-on-click-modal
    close-on-press-escape
    height="432px"
    title="添加会员"
    width="582px"
  >
    <div class="btn">
      <el-button @click="checkPhone()">
        <p>添加会员</p>
      </el-button>
      <!--      <el-button @click="addMember()">-->
      <!--        <p>添加会员</p>-->
      <!--      </el-button>-->
      <el-button @click="add">
        <p>添加游客为会员</p>
        <span>（从游客列表选择）</span>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { checkNonCycUser } from '../../api/member/member-list'

export default {
  name: 'add-member-dialog',
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
    filterForm: Object,
    unVerifiedRouteName: String,
  },
  data() {
    return {
      showNormalDialog: false, // 用回常规的添加弹窗
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.checkPhone()
      }
    },
    showNormalDialog(val) {
      if (!val) {
        // 关闭了
        this.openDialog = false
      }
    }
  },
  methods: {
    add() {
      this.$emit('non-user-dialog')
      this.openDialog = false
    },
    checkPhone() {
      let that = this
      let data = null
      this.$prompt('请输入手机号', '添加会员', {
        confirmButtonText: '确定',
        // cancelButtonText: '跳过',
        cancelButtonText: '取消',
        // 大陆、台湾、香港、澳门
        //inputPattern: /^((86){0,1}1\d{10})|((00){0,1}(886){1}0{0,1}[6,7,9](?:\d{7}|\d{8}|\d{10}))|((00){0,1}(852){1}0{0,1}[1,5,6,9](?:\d{7}|\d{8}|\d{12})$)|(^(00){0,1}(229){1}\d{6,15})$/,
        inputPattern: /^.+$/,
        inputErrorMessage: '请输入手机号',
        // distinguishCancelAndClose: true,
        inputType: 'tel',
        beforeClose: function (action, instance, done) {
          if (action === 'confirm') {
            const value = instance.inputValue
            checkNonCycUser(value)
              .then(({ data: _dta }) => {
                data = _dta
                done()
              })
              .catch(() => {
                // 可能手机号校验不通过，不能关闭
              })
          } else {
            // 用户点击取消或关闭
            done()
            this.openDialog = false
          }
        },
      })
        .then(({ value }) => {
          // done 之后
          const { is_non_cyc_user, id } = data // id: 当is_non_cyc_user等于1时返回
          if (is_non_cyc_user === 1) {
            // 已经在游客列表中
            that.$router.push({
              name: 'AddMember',
              params: {
                data: this.$enCode({
                  id: 0,
                  uid: id,
                }),
              },
            })
          } else if (is_non_cyc_user === 2) {
            that.$alert('该手机号已绑定会员数据，无需添加', '提示', {
              confirmButtonText: '知道了',
              cancelButtonText: '查看会员信息',
              distinguishCancelAndClose: true,
              showCancelButton: true,
              callback: (action) => {
                if (action === 'cancel') {
                  that.$router.push({
                    name: 'EditMember',
                    params: {
                      data: that.$enCode({
                        id,
                        type: 'normal',
                        status: 1,
                      }),
                    },
                  })
                }
              },
            })
          } else {
            // 不存在的用户手机号
            that.addMember({
              phone: value,
            }) // TODO: 携带一下手机号
          }
          that.openDialog = false
        })
        .catch((action) => {
          // const isCancel = action === 'cancel'
          // if (isCancel) {
          //   this.addMember()
          // }
          this.openDialog = false
        })
    },
    // 添加会员
    addMember(query = {}) {
      const data = {}
      data.id = 0
      data.type = this.filterForm?.tab || 'normal'
      data.status = ''

      this.filterForm.page = 1 // 新增回来，悄咪咪回到第一页✌🏻

      this.$router.push({
        name: 'AddMember',
        params: { data: this.$enCode(data) },
        query
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-vertical {
  .btn {
    display: flex;
    justify-content: center;

    .el-button {
      width: 238px;
      height: 292px;
    }
  }
}
</style>
