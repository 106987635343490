<template>
  <el-dialog
    v-if="open"
    :visible="openDialog"
    class="dialog-vertical add-integral"
    title="赠送积分"
    width="360px"
    @close="openDialog = false"
    append-to-body
  >
    <el-form ref="dForm" :model="$data" class="medium-form" label-width="30px">
      <!--        :rules="{-->
      <!--          integral: [-->
      <!--            { required: true, message: '请输入积分数量', trigger: 'blur' },-->
      <!--          ],-->
      <!--        }"-->
      <el-form-item
        prop="integral"
        :rules="[
          { required: true, message: '请输入积分数量' },
        ]"
      >
        <div class="flex">
          送
          <el-input
            v-focus="open"
            v-model="integral"
            type="number"
            style="max-width: 200px; margin: 0 20px"
            placeholder="请输入"
            controls-position="right"
          >
            <!--          <template #prepend> 送 </template> <template #append> 积分 </template>-->
          </el-input>
          积分
        </div>

        <p class="info">
          <i class="el-icon-warning" />
          <span style="margin-left: 3px">赠送积分后无法收回，请谨慎操作</span>
        </p>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { doAddIntegral } from '../../api/integral'

export default {
  name: 'AddIntegral',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    user: Object,
  },
  data() {
    return {
      integral: '',
      loading: false,
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  methods: {
    beforeSubmit(callback) {
      this.$refs.dForm.validate((valid) => {
        if (valid) {
          this.$confirm(
            '确定要赠送 ' +
              this.integral +
              ' 积分给会员 ' +
              this.user?.nickname +
              '?',
            '提示',
            {
              type: 'warning',
            }
          )
            .then(() => {
              callback()
            })
            .catch(() => {})
        }
      })
    },
    submit() {
      this.beforeSubmit(() => {
        this.loading = true
        doAddIntegral({
          user_id: this.user.user_id || this.user.id, // 用户 ID
          integral: this.integral, // 积分
        })
          .then(({ data, msg }) => {
            this.$message.success(msg)
            this.openDialog = false
          })
          .catch(() => {
            this.loading = false
          })
      })
    },
  },
}
</script>

<style scoped></style>
