<template>
  <el-dialog title="退会" :visible.sync="openDialog" width="537px" :show-close="true" class="dialog-vertical"
    @close="cancel">
    <p style="margin-bottom: 10px">
      {{ `该会员拥有 ${PositionList.length} 个职务，请选择` }}
    </p>
    <el-checkbox-group class="check-post" v-model="Ids">
      <el-tooltip :disabled="item.title_name.length < 14" class="item" effect="dark"
        :content="item.title_name" placement="bottom" v-for="(item, index) in PositionList" :key="index">
        <el-checkbox class="check-item" :label="item.user_position_id">
          {{ item.title_name }}
        </el-checkbox>
      </el-tooltip>
    </el-checkbox-group>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { ExitOrganization } from "../../api/member/member-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    PositionList: {
      type: [Array, Object],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      Ids: [],
    };
  },
  methods: {
    // 退会
    submit() {
      if (this.Ids.length) {
        let is_del_user_data = false
        this.$msgbox
          .confirm("确定要退会吗？请谨慎操作<br/>" +
            "<input type=checkbox style='vertical-align: middle;' id=is_del_user_data_multi />删除产生的评论、内容发布等数据", "提示", {
            type: "error",
            dangerouslyUseHTMLString: true,
            beforeClose: (action, instance, done) => {
              is_del_user_data = document.getElementById('is_del_user_data_multi')?.checked || false
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '正在处理退会……';
                // const loading = this.$loading({
                //   lock: true,
                //   text: '正在处理退会……'
                // });
                this.loading = true;
                ExitOrganization({
                  id: this.Ids,
                  is_del_user_data: is_del_user_data ? 1 : 0
                })
                  .then(() => {
                    instance.confirmButtonLoading = false;
                    // loading.close()
                    this.$message.success("退会成功");
                    this.updateList();
                    this.openDialog = false;
                    this.loading = false;
                  })
                  .catch(() => {
                    this.loading = false;
                    // loading.close()
                  }).finally(() => {
                    done()
                });
              } else done()
            }
          })
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 取消
    cancel() {
      this.openDialog = false;
      this.Ids = [];
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  .check-item {
    width: calc(50% - 30px);
    flex-shrink: 0;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    // @include nowrap();
  }
}
.dialog-vertical {
  ::v-deep.el-checkbox__label {
    width: 100%;
    @include nowrap();
  }
  ::v-deep.el-checkbox__input {
    height: 14px;
  }
}
</style>
