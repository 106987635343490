<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false" :onExport="onExport"
    ref="layoutFilter">
    <el-form :model="form" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">
      <!-- Line One Not Member -->
      <div class="filter-item">
        <el-form-item label="会员编号：" prop="member_number">
          <el-input v-model="form.member_number" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="单位名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader v-model="positionIds" :options="parentPositionList" :props="props"
            placeholder="请选择" clearable>
          </el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="会费状态：" prop="pay_status">
          <el-select v-model="pay_status" placeholder="请选择" clearable>
            <el-option v-for="op in payStatusList" :key="op.id" :label="op.name" :value="op.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请职务：" prop="apply_position_ids">
          <el-cascader v-model="form.apply_position_ids" :options="parentPositionList" :props="props"
                       placeholder="请选择" clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入" clearable />
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import {
  positionLevel,
  documentMemberList, exportRejectList
} from "../../api/member/member-list";

export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      parentPositionList: [],
      genderList: [
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      optionProp: {
        expandTrigger: "hover",
        label: "label",
        value: "label",
      },
      dataTypeList: [
        { id: 0, name: "真实用户" },
        { id: 1, name: "后台添加" },
      ],
      payStatusList: [
        { id: 0, name: "未缴费" },
        { id: 1, name: "已缴费" },
        { id: 2, name: "无需缴费" },
        { id: 3, name: "需补差价" },
      ],
    };
  },
  methods: {
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data;
        })
        .catch(() => {});
    },
    // 点击选择籍贯
    handleChange(e) {
      this.form.native_place = e.join(",");
      // console.log("111", e.join(","));
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      if (this.form.tab === 'nopass') {
        this.form.native_place = '';
        this.form.position = '';
      }
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      if (this.form.tab === 'nopass') {
        this.parentPositionList.forEach(item => {
          if (item.id === this.form.apply_position_ids[0]) {
            item.position.forEach(item => {
              if (item.id === this.form.apply_position_ids[1]) {
                return this.form.position = item.name
              }
            })
          }
        })
        return this.uploadFilter(this.form);
      }
      this.uploadFilter(this.form);
    },
    // 导出会员列表
    onExport() {
      const postData = { ...this.form, ids: this.ids, page: this.page, pageset: 'unit' };
      if (this.filterForm.tab === 'nopass') {
        return exportRejectList(postData).catch(() => {});
      }
      return documentMemberList(postData).catch(() => {});
    },
  },
  created() {
    this.getPositionLevel();

    this.$nextTick(() => {
      this.$layoutFilter = this.$refs.layoutFilter;
    });
  },
  watch: {
    positionIds(val) {
      this.position_id = val[val.length - 1] ? val[val.length - 1] : -1;
    },
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val;
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? "" : this.form.organization_id;
      },
      set(val) {
        this.form.organization_id = val;
      },
    },
    positionIds: {
      get() {
        return this.form.positionIds;
      },
      set(val) {
        this.form.positionIds = val;
      },
    },
    data_type: {
      get() {
        return this.form.data_type == -1 ? "" : this.form.data_type;
      },
      set(val) {
        this.form.data_type = val;
      },
    },
    pay_status: {
      get() {
        return this.form.pay_status == -1 ? "" : this.form.pay_status;
      },
      set(val) {
        this.form.pay_status = val;
      },
    },
  },
  components: {
    LayoutFilter,
  },
};
</script>
