<template>
  <el-dialog
    title="不通过原因"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
  >
    <div class="content">
      <div class="reason">
        <span>不通过原因：{{this.form.reason}}</span>
      </div>
      <p style="padding-left: 28px;">操作人：{{this.form.author}}</p>
      <p style="padding-left: 14px;">操作时间：{{this.form.check_time}}</p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="openDialog = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  .content {
    .reason {
      display: flex;
      .text {
        .el-input {
          height: 400px;
        }
      } 
    }      
    p {
        margin: 20px 0;
      }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
}
</style>