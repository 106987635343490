<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true" :onExport="onExport"
    ref="layoutFilter">
    <el-form :model="form" ref="logFilterForm" :label-width="form.tab === 'normal' ? '70px' : '85px'"
             size="medium" class="filter-from">
      <template v-for="(rules, i) in formCreateRule">
        <div v-if="(i >= 4 && (form.tab === 'normal' || form.tab === 'register')) || (i < 4)" class="filter-item" :key="i">
          <template v-for="(r, ri) in rules">
            <el-form-item v-if="r.visible === undefined || r.visible()" :key="r.prop + '_' + i + '_' + ri"
                          :label="r.label ? `${r.label}：` : ''" :prop="r.prop">
              <el-input v-if="r.type === 'input'" v-model="form[r.prop]" placeholder="请输入" clearable />
              <el-cascader v-else-if="r.type === 'cascader'" clearable @change="handleChange(r.prop, $event)"
                           :disabled="r.disabled === undefined ? false : r.disabled()"
                           v-model="form[r.prop]" :options="r.options()" :props="r.props()" placeholder="请选择" />
              <template v-else-if="r.type === 'select'">
                <el-select v-if="r.model !== undefined" :value="r.model()" @change="r.updateModel" clearable
                           :disabled="r.disabled === undefined ? false : r.disabled()">
                  <el-option v-for="item in r.options()"
                             :key="item[r.props === undefined ? 'id' : r.props().value]"
                             :label="item[r.props === undefined ? 'name' : r.props().label]"
                             :value="item[r.props === undefined ? 'id' : r.props().value]" />
                </el-select>
                <el-select v-else v-model="form[r.prop]" clearable :disabled="r.disabled === undefined ? false : r.disabled()">
                  <template v-for="item in r.options()">
                    <el-option v-if="typeof item === 'string'" :key="item" :value="item" :label="item" />
                    <el-option v-else :key="item[r.props === undefined ? 'id' : r.props().value]"
                               :label="item[r.props === undefined ? 'name' : r.props().label]"
                               :value="item[r.props === undefined ? 'id' : r.props().value]" />
                  </template>
                </el-select>
              </template>
              <!-- 日期类的比较特殊 -->
              <template v-else-if="r.type === 'age'">
                <el-date-picker type="year" v-model="age" placeholder="请选择" clearable style="width: 100%;" />
              </template>
              <template v-else-if="r.type === 'entrance_year'">
                <el-date-picker value-format="yyyy" type="year" v-model="entrance_year" placeholder="请选择" clearable style="width: 100%;" />
              </template>
              <template v-else-if="r.type === ''">

              </template>
            </el-form-item>
          </template>
        </div>
      </template>
      <!-- Line Four -->
      <div class="filter-item">
        <DatePeriodPicker v-if="form.tab === 'normal'" label="认证时间：" start-prop="start_time"
          :start-time.sync="form.start_time" end-prop="end_time" :end-time.sync="form.end_time" />
        <DatePeriodPicker v-if="form.tab !== 'normal'" label="提交时间：" start-prop="start_time"
          :start-time.sync="form.start_time" end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import {
  positionLevel,
  documentMemberList, nationality, political, exportRejectList, getCampusAndPeriod
} from "../../api/member/member-list";
import {onExportMembers} from "@/modules/organization-admin/api/school-student-list";
// import {AllClass} from "@/modules/organization-admin/api/school-class-list";

const nativePlace = require("../../assets/jsons/address.json");
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    // 联级选择配置
    const cascaderProps = {
      value: "id",
      label: "name",
      expandTrigger: "hover",
      children: "position",
    }
    return {
      parentPositionList: [],
      genderList: [
        {id: -1, name: '全部'},
        {id: 0, name: '未填写'},
        { id: 1, name: "男" },
        { id: 2, name: "女" },
      ],
      optionProp: {
        expandTrigger: "hover",
        label: "label",
        value: "label",
      },
      placeList: nativePlace,
      dataTypeList: [
        { id: 0, name: "真实用户" },
        { id: 1, name: "后台添加" },
      ],
      credentialsList: [
        { id: 0, name: "有" },
        { id: 1, name: "无" },
      ],
      payStatusList: [
        { id: 0, name: "未缴费" },
        { id: 1, name: "已缴费" },
        { id: 2, name: "无需缴费" },
        { id: 3, name: "需补差价" },
      ],
      national_arr: [],
      political_arr: [],
      // classList: [],
      campusList: [],
      periodList: [],
      formCreateRuleRaw: [
        // [
          {type: 'input', label: '用户名称', prop: 'name'},
          {
            type: 'cascader',
            label: '会内职务',
            prop: 'apply_position_ids',
            options: () => this.parentPositionList,
            // 联级选择配置
            props: () => cascaderProps,
            visible: () => this.form.tab === 'normal'
          },
          {
            type: 'input',
            label: '校友会名称',
            prop: 'keyword',
            visible: () => this.form.tab === 'nopass' || this.form.tab === 'register'
          },
          {
            type: 'cascader',
            label: '申请职务',
            prop: 'apply_position_ids',
            options: () => this.parentPositionList,
            // 联级选择配置
            props: () => cascaderProps,
            visible: () => this.form.tab !== 'normal'
          },
          {
            type: 'select',
            label: '会费状态',
            prop: 'pay_status',
            options: () => this.payStatusList,
            props: () => ({
              value: 'id',
              label: 'name'
            }),
            model: () => this.pay_status,
            updateModel: val => this.pay_status = val,
            visible: () => this.form.tab === 'normal'
          },
        // ],
        // [
          {type: 'input', label: '手机号码', prop: 'phone'},
          {
            type: 'entrance_year',
            label: '毕业年份',
            prop: 'entrance_year',
          },
          {
            type: 'select',
            label: '学段',
            prop: 'period_id',
            options: () => this.periodList
          },
        // ],
        // [
          {type: 'input', label: '班级', prop: 'class_name'},
          {type: 'select', label: '校区', prop: 'campus_id', options: () => this.campusList},
          {type: 'input', label: '班主任', prop: 'teacher'},
        // ],
        // [
          {type: 'age', label: '年龄', prop: 'age'},
          {type: 'select', label: '性别', prop: 'gender',
            model: () => this.gender,
            updateModel: val => this.gender = val,
            options: () => this.genderList},
          {
            type: 'cascader', label: '籍贯', prop: 'native_place',
            options: () => this.placeList,
            props: () => ({
              expandTrigger: "hover",
              label: "label",
              value: "label",
            }),
          },
        // ],
        // [
          {type: 'select', label: '民族', prop: 'national',
            options: () => this.national_arr},
          {type: 'select', label: '政治面貌', prop: 'political_status',
          options: () => this.political_arr,
            visible: () => this.political_arr.length > 0,
            // disabled: () => this.political_arr.length === 0
          },
        // ],
        // [
        {type: 'select' , label: '认证状态', prop: 'status', options: () => [
            {id: -1, name: '全部'},
            {id: 0, name: '未认证'},
            {id: 1, name: '已认证'}
          ],
          visible: () => this.form.tab === 'normal',
          model: () => this.auth_status,
          updateModel: val => {
            this.auth_status = val
          },
        },
          {type: 'input', label: '协助认证', prop: 'assist_auth_name',
          visible: () => this.form.tab === 'normal'
          },
          {type: 'select', label: '数据类型', prop: 'data_type',
            options: () => this.dataTypeList,
            model: () => this.data_type,
            updateModel: val => this.data_type = val,
            visible: () => this.form.tab === 'normal'
          },
        // ],
      ]
    };
  },
  methods: {
     change2Array(data, len, arr) {
      /**
       * @param data 一维数组
       * @param len 每个子数组长度
       * @param arr 保存的新数组
       */
      if (data.length <= len) {
        arr.push(data);
      } else {
        arr.push(data.splice(0, len));
        this.change2Array(data, len, arr);
      }
      return arr;
    },
    // getAllClass() {
    //   AllClass().then(res => {
    //     this.classList = res.data
    //   }).catch(err => {
    //   })
    // },
    // 获取架构职务
    getPositionLevel() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
        .then((res) => {
          this.parentPositionList = res.data;
        })
        .catch(() => {});
    },
    // 点击选择籍贯
    handleChange(prop, e) {
      if (prop === 'native_place') {
        this.form.native_place = e.join(",");
      }
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      if (this.form.tab === 'nopass') {
        this.form.period = '';
        this.form.campus = '';
        this.form.position = '';
      }
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      if (this.form.tab === 'nopass') {
        this.periodList.forEach(item => {
          if (item.id === this.form.period_id) {
            return this.form.period = item.name
          }
        })
        this.campusList.forEach(item => {
          if (item.id === this.form.campus_id) {
            return this.form.campus = item.name
          }
        })
        this.parentPositionList.forEach(item => {
          if (item.id === this.form.apply_position_ids[0]) {
            item.position.forEach(item => {
              if (item.id === this.form.apply_position_ids[1]) {
                return this.form.position = item.name
              }
            })
          }
        })
        this.form.organization = this.form.keyword
        this.form.class = this.form.class_name
        return this.uploadFilter(this.form);
      }
      this.uploadFilter(this.form);
    },
    // 导出会员列表
    onExport() {
      const postData = { ...this.form, ids: this.ids, page: this.page };
      if (this.filterForm.tab === 'nopass') {
        return exportRejectList(postData).catch(() => {});
      }
      return onExportMembers(postData).catch(() => {});
    },
  },
  created() {
    this.getPositionLevel();
    nationality().then(res => {
      this.national_arr = res.data
    }).catch(() => {
    })
    political().then(res => {
      this.political_arr = res.data
    }).catch(() => {
    })
    if (this.tenantVersion === 'school') {
      // this.getAllClass()
      getCampusAndPeriod().then(res => {
        this.campusList = res.data.campuses
        this.periodList = res.data.periods
      }).catch(() => {
      })
    }
    this.$nextTick(() => {
      this.$layoutFilter = this.$refs.layoutFilter;
    });
  },
  watch: {
    positionIds(val) {
      this.position_id = val[val.length - 1] ? val[val.length - 1] : -1;
    },
    'form.tab'() {
      this.$layoutFilter.reduction();
    }
  },
  computed: {
    formCreateRule() {
      const arr = []
      let rules = this.formCreateRuleRaw.filter(el => el.visible === undefined || el.visible())
      this.change2Array(rules, 3, arr)
      return arr
    },
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val;
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? "" : this.form.organization_id;
      },
      set(val) {
        this.form.organization_id = val;
      },
    },
    positionIds: {
      get() {
        return this.form.positionIds;
      },
      set(val) {
        this.form.positionIds = val;
      },
    },
    age: {
      get() {
        return this.form.age === -1 ? '' : this.form.age.toString()
      },
      set(val) {
        /* 点击清空图标，val 为 null */
        this.form.age = typeof val === 'string' && val ? Number(val) : (val === null ? -1 : val)
      }
    },
    entrance_year: {
      get() {
        return this.form.entrance_year === -1 ? '' : this.form.entrance_year.toString()
      },
      set(val) {
        /* 点击清空图标，val 为 null */
        this.form.entrance_year = typeof val === 'string' && val ? Number(val) : (val === null ? -1 : val)
      }
    },
    gender: {
      get() {
        return this.form.gender === -1 ? "" : this.form.gender;
      },
      set(val) {
        this.form.gender = val;
      },
    },
    political_status: {
      get() {
        return this.form.political_status
      },
      set(val) {
        return this.form.political_status = val
      }
    },
    data_type: {
      get() {
        return this.form.data_type == -1 ? "" : this.form.data_type;
      },
      set(val) {
        this.form.data_type = val;
      },
    },
    credentials: {
      get() {
        return this.form.credentials == -1 ? "" : this.form.credentials;
      },
      set(val) {
        this.form.credentials = val;
      },
    },
    pay_status: {
      get() {
        return this.form.pay_status == -1 ? "" : this.form.pay_status;
      },
      set(val) {
        this.form.pay_status = val;
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    auth_status: {
      get() {
        return this.form.status === -1 ? '' : this.form.status
      },
      set(val) {
        this.form.status = val
      }
    }
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
