<template>
  <el-dialog title="添加单位" :visible.sync="openDialog" width="582px" height="432px"
             class="dialog-vertical" close-on-press-escape close-on-click-modal>
    <div class="btn">
      <el-button @click="addUnit">
        <p>添加单位</p>
      </el-button>
      <el-button @click="add">
        <p>添加单位为会员</p>
        <span>（从企业列表选择）</span>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'add-unit-dialog',
  model: {
    prop: 'open',
    event: 'closeDialog'
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    updateList: {
      type: Function
    },
    filterForm: Object,
  },
  data() {
    return {}
  },
  methods: {
    add() {
      this.$emit('enterprise-dialog')
      this.openDialog = false
    },
    addUnit() {
      this.$router.push({
        name: 'AddUnit',
        params: {
          user_id: 0,
        },
      });
    }
  },
  watch: {},
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-vertical {
    .btn {
      display: flex;
      justify-content: center;

      .el-button {
        width: 238px;
        height: 292px;
      }
    }
  }
</style>
